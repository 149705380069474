import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { ThemConfig } from '../../types/Them';
import VerificationView from '../../publicViews/auth/VerificationView';
import { useSelector } from 'react-redux';
import PublicAccount from '../../types/public/PublicAccount';
import './index.css';

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    background: 'url(/static/images/stars.png)',
    // background: 'url(/static/images/stars.png), linear-gradient(180deg,rgb(1 1 138 / 0.3) 0,rgb(6 6 86 / 0.1))',
    backgroundSize: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    minHeight: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    justifyContent: 'center'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    maxWidth: 1600,
    marginTop: 85
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
    width: '80%'
  }
}));

function SupplierLayout({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { user } = useSelector((state: PublicAccount) => state.publicAccount);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <NavBar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
          />
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
      <VerificationView openVerification={user && !user.isVerified}></VerificationView>
    </div>
  );
}

export default SupplierLayout;
