import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, Typography, Drawer, Link } from '@material-ui/core';
import { LANGS, THEMES } from '../../../constants/enums';
import { ThemConfig } from '../../../types/Them';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import MenuButton from './Menu';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import PublicAccount from '../../../types/public/PublicAccount';
import { changeLanguage, translate } from '../../../utils/i18nHelpers';
import { logout } from '../../../actions/public/authActions';
import useSettings from '../../../hooks/useSettings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
    alignItems: 'center',
    fontFamily: 'SegoeUI-Bold',
    backgroundColor: 'transparent',
  },
  positionSticky: {},
  toolBarWrapper: {
    alignItems: 'center',
    display: 'flex',
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK ? {} : {}),
    justifyContent: 'center',
  },
  toolbar: {
    maxWidth: 1700,
    width: '100%',
    background: 'hsl(0deg 0% 100% / 50%)',
    backdropFilter: 'blur(10px)',
    paddingTop: 10,
    paddingBottom: 10,
  },
  menuButton: {
    color: '#000078',
  },
  text: {
    lineHeight: 1.3,
  },
  logo: {
    cursor: 'pointer',
    zIndex: 1,
    padding: 15,
    marginRight: '4.7368vw',
  },
  menuSection: {
    display: 'flex',
    alignItems: 'center',
  },
  centerTitle: {
    color: '#000078',
    textAlign: 'center',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: 1700,
    justifyContent: 'space-between',
    height: 85,
    paddingRight: 0,
    '&:before': {
      borderRadius: '0 0 15px 15px',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      content: '" "',
      background: 'hsl(0deg 0% 100% / 50%)',
      backdropFilter: 'blur(10px)',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    },
  },
  registration: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
    color: '#000078',
    zIndex: 1,
    marginRight: 15,
  },
  menuContainer: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
  },
  mobileDrawer: {
    width: 256,
    backgroundColor: '#282c34',
  },
  mobileMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#282c34',
    color: '#fff',
    padding: '20px 0',
  },
  mobileMenuTitle: {
    color: '#fff',
    textAlign: 'center',
    padding: '20px 0',
    background: '#3949ab',
  },
  logout: {
    color: '#000078',
    cursor: 'pointer',
    fontFamily: 'SegoeUI-Bold!important',
  },
  loginBtn: {
    color: '#000078',
    cursor: 'pointer',
    fontFamily: 'SegoeUI-Bold!important',
  },
  settingContainer: {
    zIndex: 1,
    height: '100%',
    display: 'flex',
  },
  langSelector: {
    textTransform: 'uppercase',
    background: '#000078',
    width: 100,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 15px 0',
    fontFamily: 'SegoeUI-SemiBold',
    cursor: 'pointer',
    position: 'relative',
    '&:hover $langList': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  langList: {
    opacity: 0,
    transition: 'all .2s ease-in-out',
    position: 'absolute',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '100%',
    width: '100%',
    left: 0,
    background: '#000078',
    visibility: 'hidden',
  },
  langItem: {
    textTransform: 'uppercase',
    padding: '15px 0',
  },
  arrow: {
    fill: 'white',
  },
}));

interface TopBarProps {
  className?: string;
  onMobileNavOpen: () => void;
}

export interface MenuItemProps {
  name: string;
  key: string;
  link?: string;
  children?: MenuItemChildren[];
  external?: boolean;
}

export interface MenuItemChildren {
  name: string;
  key: string;
  link: string;
  external?: boolean;
}

function TopBar({ className, onMobileNavOpen, ...rest }: TopBarProps & WrappedComponentProps) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const items: MenuItemProps[] = [
    {
      name: 'ВСЕ ТЕНДЕРЫ',
      key: 'all',
      link: '/procurement',
    },
    {
      name: 'ТМЦ',
      key: 'materials',
      link: '/procurement?type=m',
    },
    {
      name: 'Оказание услуг',
      key: 'service',
      link: '/procurement?type=s',
    },
    {
      name: 'Выполнение работ',
      key: 'works',
      link: '/procurement?type=w',
    },
    {
      name: 'Помощь',
      key: 'help',
      children: [
        {
          link: '/rules',
          key: 'rules',
          name: 'Правила участия в тендере',
        },
        {
          link: '/security',
          key: 'securityService',
          name: 'Служба безопасности',
        },
        {
          link: '/directory',
          key: 'directory',
          name: 'Справочник',
        },
        {
          link: '/faq',
          key: 'faq',
          name: 'FAQ',
        },
      ],
    },
    {
      name: 'Новости',
      key: 'news',
      link: 'https://uktmp.kz/news',
      external: true,
    },
  ];
  const [isMobileMenu, setMobileMenuOpen] = useState(false);
  const { user } = useSelector((state: PublicAccount) => state.publicAccount);
  const history = useHistory();
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState({
    language: settings.language,
  });

  const onClickLang = (lang: LANGS) => {
    saveSettings && saveSettings(lang);
    changeLanguage(lang);
    setValues({ language: lang });
  };

  const onLogout = async () => {
    await dispatch(logout());
  };

  return (
    <>
      {/*<Box className={classes.toolBarWrapper}>
          <Toolbar className={classes.toolbar}>
            <Hidden lgUp>
              <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  onClick={onMobileNavOpen}
              >
                <SvgIcon fontSize="small">
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              <Link className={ classes.logo }
                    onClick={ () => {
                      history.push('/procurement');
                    } }>
                <img
                    alt="Logo"
                    src="/static/images/logo-big.svg"
                />
              </Link>
            </Hidden>
            <Search></Search>
            <Hidden mdDown>
              <div className={classes.centerTitle} >
                <Box mr={1}>
                  <PhoneIcon/>
                </Box>
                <Typography
                    variant="h3">
                  +7 (7232) 23 30 15
                </Typography>
              </div>
            </Hidden>
          </Toolbar>
        </Box>*/}
      <AppBar className={clsx(classes.root, classes.positionSticky, className)} {...rest}>
        <Toolbar className={classes.menu}>
          <Box className={classes.menuSection}>
            <Hidden mdDown>
              <Link
                className={classes.logo}
                onClick={() => {
                  history.push('/dashboard');
                }}
              >
                <img alt="Logo" src="/static/images/logo-big.svg" />
              </Link>
            </Hidden>
            <Hidden lgUp>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                onClick={() => {
                  setMobileMenuOpen(true);
                }}
              >
                Меню <ArrowDropDownIcon />
              </IconButton>
            </Hidden>
            <Hidden lgUp>
              <Drawer
                classes={{ paper: classes.mobileDrawer }}
                onClose={() => {
                  setMobileMenuOpen(false);
                }}
                open={isMobileMenu}
                variant="temporary"
              >
                <Typography variant="h3" className={classes.mobileMenuTitle}>
                  Меню
                </Typography>
                <div className={classes.mobileMenuContainer}>
                  {items.map((menu: MenuItemProps, index: number) => {
                    return (
                      <MenuButton
                        onClickMobileMenu={() => {
                          setMobileMenuOpen(false);
                        }}
                        key={index}
                        menu={menu}
                      >
                        {translate(rest, `pub:TopBar;Menu;${menu.key}:item`, menu.name)}
                      </MenuButton>
                    );
                  })}
                </div>
              </Drawer>
            </Hidden>
            <Hidden mdDown>
              <div className={classes.menuContainer}>
                {items.map((menu: MenuItemProps, index: number) => {
                  return (
                    <MenuButton key={index} menu={menu}>
                      {translate(rest, `pub:TopBar;Menu;${menu.key}:item`, menu.name)}
                    </MenuButton>
                  );
                })}
              </div>
            </Hidden>
          </Box>
          <Box className={classes.settingContainer}>
            <div className={classes.registration}>
              <PersonOutlineIcon />
              {!user && (
                <>
                  {/*<LoginView></LoginView> / */}
                  <Link
                    className={classes.loginBtn}
                    onClick={() => {
                      history.push('/registration');
                    }}
                  >
                    <FormattedMessage id="pub;Registration;title" defaultMessage="Registration" />
                  </Link>
                </>
              )}
              {user && (
                <Typography variant="h5">
                  {/*{ `${translate(rest, 'pub:TopBar;menu;LoggedInAs;title', 'Logged in as')} ${ user.orgName } ` }*/}
                  <Link onClick={onLogout} className={classes.logout}>
                    Выйти
                  </Link>
                </Typography>
              )}
            </div>
            <div className={classes.langSelector}>
              <Box display="flex">
                {values.language}
                <ExpandMoreIcon className={classes.arrow} />
              </Box>
              <div className={classes.langList}>
                {Object.values(LANGS).map(lang => (
                  <div
                    key={lang}
                    className={classes.langItem}
                    onClick={() => {
                      onClickLang(lang);
                    }}
                  >
                    {lang}
                  </div>
                ))}
              </div>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default injectIntl(TopBar);
