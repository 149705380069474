import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
  colors,
  makeStyles, TextField, FormHelperText, Button, DialogTitle, DialogContent, Dialog,
} from '@material-ui/core';
import VerificationForm from './VerificationForm';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ThemConfig } from '../../../types/Them';
import { translate } from '../../../utils/i18nHelpers';
import { logout, verify } from '../../../actions/public/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { allowOnlyNumbers } from '../../../utils/helper';
import PublicAccount from '../../../types/public/PublicAccount';

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backButton: {
    marginLeft: theme.spacing(2)
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logoText: {
    color: 'white',
    padding: theme.spacing(3),
    backgroundColor: '#0000006b',
    margin: '0 -24px'
  },
  modalTitle: {
    fontSize: '20px'
  },
  modalContent: {
    paddingBottom: 20
  }
}));

function VerificationView({ openVerification, ...props }: { openVerification: boolean } & WrappedComponentProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showCodeField, setShowCodeField] = useState(false);
  const [verifyOption, setVerifyOption] = useState<string>('');
  const [errorCode, setErrorCode] = useState();
  const [code, setCode] = useState<string>('');
  const { user } = useSelector((state: PublicAccount) => state.publicAccount);
  const [open, setOpen] = React.useState(false);
  const handleSendSuccess = (option: string) => {
    setShowCodeField(true);
    setVerifyOption(option);
  };

  useEffect (() => {
    if (openVerification) {
      setOpen(true);
    }
  }, [openVerification]);

  const handleClose = async () => {
    setOpen(false);
    setCode('');
    setShowCodeField(false);
    if (!user.isVerified) {
      await dispatch(logout());
    }
  };

  const verifyCode = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (allowOnlyNumbers(value)) {
      setCode(value);
      if (value && value.length === 6) {
        try {
          await dispatch(verify(value, user.id, user.email, verifyOption));
          setOpen(false);
        } catch (e) {
          setErrorCode(translate(props, 'app:Verification;CodeError'));
        }
      }
    }
  };

  return (
      <>
        <Dialog
            open={ open } onClose={ handleClose }>
          <DialogTitle >
            <span className={classes.modalTitle}>
              <FormattedMessage id="app:Verification;title" defaultMessage="You are logging into the Portal, you must pass verification." />
            </span>
          </DialogTitle>
          <DialogContent className={classes.modalContent}>
            <VerificationForm onSendSuccess={handleSendSuccess} props={props}/>
            <Divider />
            { showCodeField &&
            <>
              <TextField
                  fullWidth
                  onChange={ verifyCode }
                  label={
                    <FormattedMessage id="app:Verification;Code"
                                      defaultMessage="Code"/>
                  }
                  inputProps={{ maxLength: 6 }}
                  margin="normal"
                  name="code"
                  type="text"
                  variant="outlined"
                  value={code}
              />
              { errorCode && (
                  <FormHelperText error>
                    { errorCode }
                  </FormHelperText>
              ) }
            </>
            }
          </DialogContent>
        </Dialog>
      </>
  );
}

export default injectIntl(VerificationView);
