import axios from '../utils/axios';
import { API } from '../constants/constants';
import SupplierSearch from '../types/public/SupplierSearch';
import User from "../types/User";

class SupplierService {
    searchSupplier = (bin:string) => new Promise<SupplierSearch[]>((resolve, reject) => {
        axios.get(`${ API }/Supplier/Search?bin=${bin}`)
            .then((response) => {
                if (response.data) {
                    let values = {...response.data};
                    delete values.chiefName;
                    resolve(values);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getSupplierChecks = (bin: any, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Supplier/CheckReport?bin=${ bin }&refresh=${ needRefresh }`)
            .then((response) => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplierBasic = (bin: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/${bin}/Basic?refresh=${needRefresh}`)
            .then((response) => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplierNds = (bin: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/${bin}/NDS?refresh=${needRefresh}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    resolve(null)
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplierLicenses = (bin: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/${bin}/Licenses?refresh=${needRefresh}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    resolve(null)
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplierStatus = (bin: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/${bin}/Statuses?refresh=${needRefresh}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    resolve(null)
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplierGraph = (bin: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/${bin}/Graph?refresh=${needRefresh}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    resolve(null)
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplierFounders = (bin: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/${bin}/Founders?refresh=${needRefresh}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    resolve(null)
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplierSNG = (country: string, bin: string, method: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/Legat/${country}/${bin}/${method}?refresh=${needRefresh}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    resolve(null)
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getCheckSupplier = (bin: string, needRefresh: boolean) => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/Kompra/${bin}/All?refresh=${needRefresh}`, {timeout: 120000})
            .then((response) => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getFAQList = () => new Promise<any[]>((resolve, reject) => {
        axios.get(`${API}/GlobalFaq`)
            .then((response) => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    getStaff = () => {
        return [
            ]
    };

    addStaff = (data: any) => new Promise<any>((resolve, reject) => {
        axios.post(`${ API }/Supplier/AddEmployee`, data )
            .then((response) => {
                if (response.data) {
                    resolve(true);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    removeStaff = (id: number) => new Promise<boolean>((resolve, reject) => {
        axios.delete(`${ API }/Supplier/Employee/${id}`)
            .then((response) => {
                if (response.data) {
                    resolve(true);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

const supplierService = new SupplierService();

export default supplierService;
