import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, makeStyles, Avatar, List, ListItem, ListSubheader, Icon } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { useSelector } from 'react-redux';
import PublicAccount from '../../../types/public/PublicAccount';
import GroupIcon from '@material-ui/icons/Group';
import GavelIcon from '@material-ui/icons/Gavel';
import StarIcon from '@material-ui/icons/Star';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CommentIcon from '@material-ui/icons/Comment';
import SendIcon from '@material-ui/icons/Send';
import DescriptionIcon from '@material-ui/icons/Description';
import SmsIcon from '@material-ui/icons/Sms';
import AddIcon from '@material-ui/icons/Add';
import FolderList from '../../../types/public/FolderList';
import FolderIcon from '@material-ui/icons/Folder';
import DoneIcon from '@material-ui/icons/Done';
import _ from 'lodash';
import { SearchTemplateList } from '../../../types/public/SearchTemplate';
import tenderService from '../../../publicServices/tenderService';
import moment from 'moment';
import documentsService from '../../../publicServices/documentsService';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'SegoeUI!important',
    color: '#fff',
  },
  page: {
    padding: 20,
    borderRadius: '0 0 15px 15px',
    marginBottom: 8,
    background: 'linear-gradient(180deg,#01018a 0,#060656)',
    color: '#fff',
  },
  helpPageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  content: {},
  icon: {
    marginRight: 15,
  },
  orange: {
    color: 'orange',
  },
  red: {
    color: 'red',
  },
  info: {
    fontFamily: 'SegoeUI-Bold!important',
  },
  infoUser: {
    textAlign: 'center',
  },
  links: {
    color: '#000078',
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  mainMenu: {
    padding: '0 10px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  menuTitle: {
    width: '100%',
  },
  black: {
    color: 'black',
  },
  grey: {
    color: 'grey',
  },
  blue: {
    color: 'blue',
  },
  green: {
    color: 'green',
  },
  yellow: {
    color: 'yellow',
  },
  badge: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'red',
    width: 20,
    height: 20,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
  },
}));

const navConfig: any = {
  myOrg: [
    {
      name: 'Личный кабинет',
      key: '',
      icon: AccountBoxIcon,
      href: '/profile',
      needData: false,
      hasBadg: false,
    },
    {
      name: 'Мои сотрудники',
      key: 'staff',
      icon: GroupIcon,
      href: '/staff',
      needData: true,
      hasBadg: false,
    },
    {
      name: 'Мои документы',
      key: 'docs',
      icon: DescriptionIcon,
      href: '/docs',
      needData: true,
      hasBadg: true,
    },
  ],
  tenders: [
    {
      name: 'Мои тендеры',
      key: 'myTenders',
      icon: GavelIcon,
      href: '/myTenders',
      needData: true,
      hasBadg: false,
    },
    {
      name: 'Избранные',
      key: 'fav',
      icon: StarIcon,
      href: '/favorite',
      needData: true,
      hasBadg: false,
    },
    {
      name: 'Победитель',
      key: 'winner',
      icon: CheckCircleIcon,
      href: '/winner',
      needData: true,
      hasBadg: false,
    },
  ],
  searchTemplates: [],
  folders: [],
  additionally: [
    {
      name: 'Мои обсуждения',
      key: '',
      icon: SmsIcon,
      href: '/',
      needData: false,
      hasBadg: false,
    },
    {
      name: 'Рассылка',
      key: '',
      icon: SendIcon,
      href: '/',
      needData: false,
      hasBadg: false,
    },
    {
      name: 'Мои заметки',
      key: '',
      icon: CommentIcon,
      href: '/withNotes',
      needData: false,
      hasBadg: false,
    },
  ],
};

export interface UtilsProps {
  utils: {
    updateMenuCount: number;
  };
}

function Menu({ ...rest }) {
  const classes: any = useStyles();
  const { updateMenuCount } = useSelector((state: UtilsProps) => state.utils);
  const { user } = useSelector((state: PublicAccount) => state.publicAccount);
  const { folders } = useSelector((state: FolderList) => state.folderList);
  const [active, setActiveLength] = useState(0);
  const [staffCount, setStaffCount] = useState(user.employees.length);
  const [myTenders, setMyTenders] = useState(0);
  const [winnerCount, setWinnerCount] = useState(0);
  const [favLength, setFavLength] = useState(0);
  const [requestedDocsLength, setRequestedDocsLength] = useState(0);
  const [docsLength, setDocsLength] = useState(0);
  navConfig.folders = _.orderBy(folders, ['order'], 'asc');

  const { searchTemplates } = useSelector((state: SearchTemplateList) => state.searchTemplate);
  navConfig.searchTemplates = _.orderBy(searchTemplates, ['order'], 'asc');

  const photoUrl = `${user.photoUrl}?${Date.now()}`;

  function getFieldData(fieldKey: string) {
    switch (fieldKey) {
      case 'staff':
        return staffCount;
      case 'active':
        return active;
      case 'myTenders':
        return myTenders;
      case 'winner':
        return winnerCount;
      case 'fav':
        return favLength;
      case 'docs':
        return docsLength;
      default:
        return 0;
    }
  }

  const getFieldBadge = useCallback(
    (fieldKey: string) => {
      switch (fieldKey) {
        case 'docs':
          return requestedDocsLength || 0;
        default:
          return 0;
      }
    },
    [requestedDocsLength]
  );

  const getTenders = useCallback(async () => {
    const tenders = await tenderService.getActiveTenders();
    setMyTenders(tenders.length);

    const activeCount = tenders.filter((tender: any) => {
      return tender.endDate > moment();
    });
    setActiveLength(activeCount.length);

    const winnerCount = tenders.filter((tender: any) => {
      return tender.isWinner;
    });
    setWinnerCount(winnerCount.length);
  }, []);

  const getTendersFav = useCallback(async () => {
    const tenders = await tenderService.getList('', '/favorite');
    setFavLength(tenders.length);
  }, []);

  const getDocs = useCallback(async () => {
    const supplierDocs = await documentsService.getDocuments();
    setRequestedDocsLength(supplierDocs?.requestDocuments?.length || 0);
    const groupedDocs = _.groupBy(supplierDocs.participationInTender, 'name');
    setDocsLength(Object.keys(groupedDocs).length + (supplierDocs?.other?.length || 0));
  }, []);

  useEffect(() => {
    getTenders();
    getTendersFav();
    getDocs();
  }, [updateMenuCount]);

  return (
    <>
      <Box className={classes.page}>
        <Box className={classes.content}>
          <div className={classes.contentItem}>
            <div className={classes.avatarContainer}>
              <Avatar alt="User" className={classes.avatar} src={photoUrl} />
            </div>
            <div className={classes.infoUser}>
              <Typography variant={'h3'}>{user.name}</Typography>
            </div>
            <div className={classes.infoUser}>
              <Typography variant={'h6'}>{user.orgName}</Typography>
            </div>
          </div>
        </Box>
        <Box className={classes.mainMenu}>
          <List
            subheader={
              <ListSubheader className={clsx(classes.root, classes.upperCase)}>{'МОЯ ОРГАНИЗАЦИЯ'}</ListSubheader>
            }
            component="nav"
            className={classes.root}
          >
            {navConfig.myOrg.map((item: any, i: number) => {
              const Icon = item.icon;
              const color = item.color ? classes[item.color] : '';
              return (
                <ListItem key={i + item.name} button component={RouterLink} to={item.href}>
                  {Icon && <Icon className={clsx(classes.icon, color)} size="20" />}
                  <span className={classes.menuTitle}>{item.name}</span>

                  <Typography variant="subtitle2">{item.needData && getFieldData(item.key)}</Typography>

                  {item.hasBadg && !!getFieldBadge(item.key) && (
                    <div className={classes.badge}>{getFieldBadge(item.key)}</div>
                  )}
                </ListItem>
              );
            })}
          </List>
          <List
            subheader={<ListSubheader className={clsx(classes.root, classes.upperCase)}>{'Тендеры'}</ListSubheader>}
            component="nav"
            className={classes.root}
          >
            {navConfig.tenders.map((item: any, i: number) => {
              const Icon = item.icon;
              const color = item.color ? classes[item.color] : '';
              return (
                <ListItem key={i + item.name} button component={RouterLink} to={item.href}>
                  {Icon && <Icon className={clsx(classes.icon, color)} size="20" />}
                  <span className={classes.menuTitle}>{item.name}</span>

                  <Typography variant="subtitle2">{item.needData && getFieldData(item.key)}</Typography>

                  {item.hasBadg && !!getFieldBadge(item.key) && (
                    <div className={classes.badge}>{getFieldBadge(item.key)}</div>
                  )}
                </ListItem>
              );
            })}
          </List>
          <List
            subheader={
              <ListSubheader className={clsx(classes.root, classes.upperCase)}>{'Шаблоны поиска'}</ListSubheader>
            }
            component="nav"
            className={classes.root}
          >
            <ListItem button component={RouterLink} to="/newSearchTemplate">
              <AddIcon className={clsx(classes.icon)} />
              <span className={classes.menuTitle}>Менеджер шаблонов</span>
            </ListItem>
            {navConfig.searchTemplates.map((item: any, i: number) => {
              const Icon = item.icon;
              const color = item.color ? classes[item.color] : '';
              return (
                <ListItem key={i + item.name} button component={RouterLink} to={`/searchTemplate/${item._id}`}>
                  {Icon ? (
                    <Icon className={clsx(classes.icon, color)} size="20" />
                  ) : (
                    <StarIcon className={clsx(classes.icon, color)} />
                  )}
                  <span className={classes.menuTitle}>{item.name}</span>

                  <Typography variant="subtitle2">{item.needData && getFieldData(item.key)}</Typography>

                  {item.hasBadg && !!getFieldBadge(item.key) && (
                    <div className={classes.badge}>{getFieldBadge(item.key)}</div>
                  )}
                </ListItem>
              );
            })}
          </List>

          <List
            subheader={<ListSubheader className={clsx(classes.root, classes.upperCase)}>{'Папки'}</ListSubheader>}
            component="nav"
            className={classes.root}
          >
            <ListItem button component={RouterLink} to="/newFolder">
              <AddIcon className={clsx(classes.icon)} />
              <span className={classes.menuTitle}>Менеджер папок</span>
            </ListItem>
            <ListItem button component={RouterLink} to="/activeTenders">
              <DoneIcon className={clsx(classes.icon, classes.orange)} />
              <span className={classes.menuTitle}>В работе</span>
              <Typography variant="subtitle2">{active}</Typography>
            </ListItem>
            {navConfig.folders.map((item: any, i: number) => {
              const Icon = item.icon;
              const color = item.color ? classes[item.color] : '';
              return (
                <ListItem key={i + item.name} button component={RouterLink} to={`/folder/${item.id}`}>
                  {Icon ? (
                    <Icon className={clsx(classes.icon, color)} size="20" />
                  ) : (
                    <FolderIcon className={clsx(classes.icon, color)} />
                  )}
                  <span className={classes.menuTitle}>{item.name}</span>

                  <Typography variant="subtitle2">{item.count}</Typography>
                </ListItem>
              );
            })}
          </List>
          <List
            subheader={
              <ListSubheader className={clsx(classes.root, classes.upperCase)}>{'Дополнительно'}</ListSubheader>
            }
            component="nav"
            className={classes.root}
          >
            {navConfig.additionally.map((item: any, i: number) => {
              const Icon = item.icon;
              const color = item.color ? classes[item.color] : '';
              return (
                <ListItem key={i + item.name} button component={RouterLink} to={item.href}>
                  {Icon && <Icon className={clsx(classes.icon, color)} size="20" />}
                  <span className={classes.menuTitle}>{item.name}</span>

                  <Typography variant="subtitle2">{item.needData && getFieldData(item.key)}</Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </>
  );
}

export default Menu;
