import axios from '../utils/axios';
import { API } from '../constants/constants';
import Documents from '../types/public/Documents';

class DocumentsService {
  getDocuments = () =>
    new Promise<Documents>((resolve, reject) => {
      axios
        .get(`${API}/UserDocument`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  downloadDocument = (id: number, type: string = '') =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/UserDocument/${type ? type + '/' : ''}${id}/Download`, {
          responseType: 'blob',
        })
        .then(response => {
          resolve({
            fileData: response.data,
            type: response.headers['content-type'],
          });
        })
        .catch(error => {
          reject(error);
        });
    });

  uploadDocument = (data: FormData) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/UserDocument/Upload`, data)
        .then(response => {
          if (response.data) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  uploadTenderRequestedDocument = (data: FormData, docId: number) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/UserDocument/Upload/RequestDocuments/${docId}`, data)
        .then(response => {
          if (response.data) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  removeFile = (id: number) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .delete(`${API}/UserDocument/${id}/Delete`)
        .then(response => {
          if (response.data) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

const documentsService = new DocumentsService();

export default documentsService;
