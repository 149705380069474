import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import User from "../types/User";
import PublicAccount from "../types/public/PublicAccount";

/*interface AuthRouteProps {
    component?: any,
    render?: any,
    exact?: any,
    path?: any,
}*/

interface State {
    account: {
        user: User,
    },
}

function PublicAuthRoute({ component: Component, render, ...rest }: any) {
    const account = useSelector((state: PublicAccount) => state.publicAccount);

    if (!account.user) {
        return <Redirect to="/procurement"/>;
    }
    // if (account.user && !account.user.isVerified) {
    //     return <Redirect to="/dashboard"/>;
    // }

    return render ? render({ ...rest }) : <Component { ...rest } />;
}

export default PublicAuthRoute;
