import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  makeStyles, Typography
} from '@material-ui/core';
import Logo from '../../../components/Logo';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Login from './Login';
import Help from './Help';
import { useSelector } from 'react-redux';
import User from '../../../types/public/User';
import PublicAccount from '../../../types/public/PublicAccount';
import VerificationView from '../../../publicViews/auth/VerificationView';
import Menu from './Menu';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopNavBar: {
    width: '20%'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  mobileLogo: {
    textAlign: 'center'
  },
  loggedIn: {
    padding: 10,
    textAlign: 'center'
  }
}));

interface NavBarProps {
  onMobileClose: ()=>void;
  openMobile: boolean;
}

function NavBar({ openMobile, onMobileClose, ...props }: NavBarProps & WrappedComponentProps) {
  const classes = useStyles();
  const location = useLocation();
  const [showVerification, setShowVerification] = React.useState(false);
  const { user } = useSelector((state: PublicAccount) => state.publicAccount);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink className={ classes.mobileLogo} to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        { !user &&
          <Login></Login>
        }
        { user &&
        <Menu></Menu>
        }
        {/*<Help></Help>*/}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <div
          className={classes.desktopNavBar }
        >
          {content}
        </div>
      </Hidden>
    </>
  );
}

export default injectIntl(NavBar);

