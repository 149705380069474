import React from 'react';
import {
    makeStyles,
    Dialog,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Button
} from '@material-ui/core';
import clsx from "clsx";
import {injectIntl, WrappedComponentProps} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
    }

}));

interface LicenseDialogProps {
    className?: string;
    license: any;
    open: boolean;
    closeLicenseDialog: any;
}

function LicenseDialog({className, open, license, closeLicenseDialog, ...rest}: LicenseDialogProps & WrappedComponentProps) {
    const classes = useStyles();


    return (
            <Dialog
                maxWidth="lg"
                onClose={closeLicenseDialog}
                open={open}
            >
                <div
                    className={clsx(classes.root, className)}
                    {...rest}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Номер документа
                                </TableCell>
                                <TableCell>
                                    Статус
                                </TableCell>
                                <TableCell>
                                    Название
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {license.map((item: any, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {item.doc_id}
                                        </TableCell>
                                        <TableCell>
                                            {item.status}
                                        </TableCell>
                                        <TableCell>
                                            {item.field}
                                        </TableCell>
                                    </TableRow>
                                )
                            }) }
                        </TableBody>
                    </Table>

                    <Box
                        mt={3}
                        p={3}
                    >
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={closeLicenseDialog}
                        >
                            Закрыть
                        </Button>
                    </Box>
                </div>
            </Dialog>

    );
}

export default injectIntl(LicenseDialog);