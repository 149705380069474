import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  colors,
  makeStyles,
  Button,
  DialogActions,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Link,
  IconButton,
  SvgIcon,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  CardHeader,
  Grid,
  withStyles,
  StepConnector,
  StepIconProps,
} from '@material-ui/core';
import { ThemConfig } from '../../../types/Them';
import clsx from 'clsx';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import User from '../../../types/User';
import { Link as RouterLink } from 'react-router-dom';
import { translate } from '../../../utils/i18nHelpers';
import authService from '../../../publicServices/authService';
import * as Yup from 'yup';
import EditIcon from '@material-ui/core/SvgIcon/SvgIcon';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import HelpIcon from '@material-ui/icons/Help';
import { useSnackbar } from 'notistack';
import LoginView from '../LoginView';
import moment from 'moment';
import NewFileItem from '../RegisterView/NewFileItem';
import { Check } from '@material-ui/icons';
import supplierService from '../../../publicServices/supplierService';
import LicenseDialog from '../RegisterViewRedesign/LicenseDialog';
import _ from 'lodash';

const useStyles = makeStyles((theme: ThemConfig) => ({
  card: {
    background: 'hsl(0deg 0% 100% / 50%)',
    backdropFilter: 'blur(10px)',
    border: '1px solid #979696',
    marginBottom: 20,
    padding: 15,
  },
  root: {
    minWidth: 400,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#000078',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000099',
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    minWidth: 400,
  },
  tabItem: {
    textTransform: 'none',
    color: '#000078',
    fontFamily: 'SegoeUI-Bold!important',
  },
  selectedTab: {
    borderBottom: '1px solid #000078',
    borderRadius: 0,
    fontFamily: 'SegoeUI',
  },
  phoneNumber: {
    width: '100%',
    padding: '17px 14px',
    border: '1px solid rgb(209, 209, 209)',
    borderRadius: '4px',
    fontFamily: 'SegoeUI',
    fontSize: 'inherit',
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 1px blue',
    },
    '&:hover': {
      border: '1px solid black',
    },
  },
  error: {
    border: '1px solid red',
    // color: 'red'
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  policy: {
    maxHeight: 400,
    overflow: 'auto',
    marginTop: 10,
  },
  helperText: {
    marginLeft: 18,
  },
  helpText: {
    color: 'white',
  },
  stepperActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  stepper: {
    backgroundColor: 'transparent',
    width: 195,
  },
  binBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  binButton: {
    height: 55,
    marginLeft: 15,
    marginTop: 6,
  },
  katoButton: {
    height: 55,
    marginLeft: 15,
  },
  datePicker: {
    marginRight: 15,
    minWidth: 260,
  },
  firstChief: {
    display: 'flex',
    flexDirection: 'row',
  },
  passwordBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  binIinWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  notResidentWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  formAndActions: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginRight: {
    marginRight: '15px',
  },
  flag: {
    marginRight: '5px',
  },
  menuItemWrapper: {
    display: 'flex',
  },
  buttonCenter: {
    textAlign: 'center',
  },
  disabled: {
    color: 'grey!important',
  },
  certificate: {},
  modalTitle: {
    fontSize: '20px',
  },
  link: {
    color: '#000078',
  },
}));

interface Response {
  responseObject?: any;
  code?: string;
  errorCode?: any;
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'flex',
    height: 35,
    alignItems: 'center',
    width: 35,
    justifyContent: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleText: {
    color: 'white',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 23,
  },
});

const QontoConnector = withStyles({
  vertical: {
    marginLeft: 17,
    padding: '5px 0',
  },
})(StepConnector);

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle}>
          <span className={classes.circleText}>{props.icon}</span>
        </div>
      )}
    </div>
  );
}

const requiredFieldsByStep = [
  ['bin', 'supplierName'],
  ['organizationData.phone', 'organizationData.email'],
  [],
  ['firstChief.email', 'firstChief.phone', 'password', 'passwordConfirmation'],
  [],
  [
    'attachedDocs.charter',
    'attachedDocs.directorAppointmentOrder',
    'attachedDocs.certificateOfStateReRegistration',
    'attachedDocs.certificateVATRegistration',
    'attachedDocs.licenseCopy',
    'attachedDocs.identityDocument',
    'policy',
  ],
  [],
];

function DigitalSignatureView({
  onSubmitSuccess,
  hideLink,
  mode,
  className,
  close,
  ...rest
}: {
  onSubmitSuccess?: Function;
  hideLink?: boolean;
  mode: string;
  className?: string;
  close?: Function;
} & WrappedComponentProps) {
  const ws = useRef<WebSocket>();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [ready, setReady] = useState(false);
  const [state, setState] = useState<any>({ registrationData: {} });
  const [open, setOpen] = useState(false);
  const [openLicence, setOpenLicence] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    '1. Сведения о компании',
    '2. Юридический адрес',
    '3. Учредители',
    '4. Руководитель',
    '5. Дополнительный пользователь',
    '6. Документы',
    '7. Соглашения',
  ];
  const [selectedSupplier, setSelectedSupplier] = useState<any>(null);
  const [binOrIin, setBinOrIin] = useState('');
  const [needAdditionalUser, setNeedAdditionalUser] = useState(true);
  const [policySign, setPolicySign] = useState(false);

  const getFieldData = (fieldName: string, data = []) => {
    const foundFiled =
      data &&
      data.find((item: any) => {
        return item.type === fieldName;
      });
    if (foundFiled) {
      return foundFiled;
    }
    return {
      value: 'Нет данных',
    };
  };

  useEffect(() => {
    tryConnect();

    return () => {
      ws.current!.close();
    };
  }, [setReady]);

  const tryConnect = () => {
    ws.current = new WebSocket('wss://127.0.0.1:13579/');

    ws.current.onopen = e => {
      // tslint:disable-next-line
      console.log('connection opened');
      setReady(true);
    };
    ws.current.onclose = e => {
      if (e.wasClean) {
        // tslint:disable-next-line
        console.log('connection closed');
      } else {
        // tslint:disable-next-line
        console.log('connection error: [code]=' + e.code + ', [reason]=' + e.reason);
        setOpen(true);
      }
      setReady(false);
    };
  };

  const needAdditionalUserChange = () => {
    setNeedAdditionalUser(!needAdditionalUser);
  };

  const handleNext = (values: any, setErrors?: Function, setFieldTouched?: Function) => {
    if (activeStep === 3 && !needAdditionalUser) {
      setActiveStep(prevActiveStep => {
        if (checkEmptyFiled(prevActiveStep, setErrors)) {
          return prevActiveStep;
        }
        return prevActiveStep + 2;
      });
    } else {
      setActiveStep(prevActiveStep => {
        if (checkEmptyFiled(prevActiveStep, setErrors)) {
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      });
    }

    function checkEmptyFiled(prevActiveStep: number, setErrors?: Function) {
      let invalid = false;
      const fieldErrors: any = {};
      _.forEach(requiredFieldsByStep[prevActiveStep], (path: string, index: number) => {
        if (prevActiveStep === 5 && binOrIin === 'iin' && index === 1) {
          return;
        }
        if (prevActiveStep === 5 && binOrIin === 'bin' && (index === 4 || index === 5)) {
          return;
        }
        const value = _.get(values, path, '');
        const empty = _.isEmpty(value);
        if (prevActiveStep === 3 && index === 0) {
          const isValidEmail = value.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
          if (!isValidEmail) {
            _.set(fieldErrors, path, translate(rest, 'app:Global;validation:required', 'Required'));
            if (setFieldTouched) {
              setFieldTouched(path);
            }
            if (!invalid) {
              invalid = true;
            }
          }
        }
        if (empty) {
          _.set(fieldErrors, path, translate(rest, 'app:Global;validation:required', 'Required'));
          if (setFieldTouched) {
            setFieldTouched(path);
          }
        }
        if (!invalid && empty) {
          invalid = empty;
        }
      });
      if (setErrors) {
        setErrors(fieldErrors);
      }
      if (prevActiveStep === 5 && Object.keys(fieldErrors).length === 1 && Boolean(fieldErrors.policy)) {
        return false;
      }
      return invalid;
    }
  };

  const handleBack = () => {
    if (activeStep === 5 && !needAdditionalUser) {
      setActiveStep(prevActiveStep => prevActiveStep - 2);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const checkXml = async (signXml: string) => {
    const responseData = await authService.checkXmlAfterSign(signXml);
    const bin = responseData ? responseData.organizationalNumber : '';

    const dataChecks: any = await supplierService.getCheckSupplierBasic(bin, false);
    const nds: any = await supplierService.getCheckSupplierNds(bin, false);
    const licenses: any = await supplierService.getCheckSupplierLicenses(bin, false);
    let graph: any = await supplierService.getCheckSupplierGraph(bin, false);
    // const founders: any = await supplierService.getCheckSupplierFounders(bin, false);
    setSelectedSupplier({
      generalInfo: {
        bin: dataChecks.bin ? dataChecks.bin : 'Нет данных',
        okpo: dataChecks.okpo ? dataChecks.okpo : 'Нет данных',
        kato: dataChecks.kato ? dataChecks.kato : 'Нет данных',
        registerDate: dataChecks.registerDate ? moment(dataChecks.registerDate).format('DD.MM.YYYY') : 'Нет данных',
        supervisor: dataChecks.owner ? dataChecks.owner : 'Нет данных',
        krp: dataChecks.workers ? dataChecks.workers : 'Нет данных',
        oked: dataChecks.oked ? { oked: dataChecks.oked, fields: dataChecks.field } : 'Нет данных',
        VATPayer: nds ? nds : null,
        license: licenses ? licenses.content : 0,
      },
    });
    if (graph && graph.graphStatus === 'SYNC') {
      graph = await supplierService.getCheckSupplierGraph(bin, true);
    }
    /*callback('legalAddress.kato', dataChecks.kato);
        callback('krp', dataChecks.workers ? dataChecks.workers : '');
        callback('okpo', dataChecks.okpo ? dataChecks.okpo : '');
        callback('oked', dataChecks.oked ? {oked: dataChecks.oked, fields: dataChecks.field} : '');
        callback('vatPayer', nds ? nds : {});
        callback('license', licenses ? licenses.content : []);
        callback('legalAddress.region', dataChecks.region);
        callback('legalAddress.roomNumber', dataChecks.lawAddress);
        callback('firstChief.name', dataChecks.owner);
        callback('supplierName', dataChecks.fullName);
        callback('organizationData.email', dataChecks.email);
        callback('organizationData.phone', dataChecks.phone);
        callback('organizationData.site', dataChecks.website);
        callback('chiefBin', graph ? graph.owner.iin : '');
        callback('chiefName', graph ? graph.owner.name : '');*/

    // const responseData = await authService.checkXmlAfterSign(signXml);
    // const dataChecks: any = await supplierService.getSupplierChecks(responseData.organizationalNumber, false);
    /*setSelectedSupplier({
            generalInfo: {
                bin: dataChecks.bin ? dataChecks.bin : 'Нет данных',
                okpo: dataChecks.okpo ? dataChecks.okpo : 'Нет данных',
                kato: dataChecks.kato ? dataChecks.kato : 'Нет данных',
                registerDate: dataChecks.registerDate ? dataChecks.registerDate : 'Нет данных',
                supervisor: getFieldData('Руководитель', dataChecks.fields),
                status: getFieldData('Статус', dataChecks.fields),
                krp: getFieldData('КРП', dataChecks.fields),
                oked: getFieldData('ОКЭД', dataChecks.fields),
                VATPayer: getFieldData('Плательщик НДС', dataChecks.fields),
                license: getFieldData('Лицензии', dataChecks.fields)
            },
        });*/
    setState({ ...state, registrationData: responseData, ...dataChecks });
    switch (Number(dataChecks.bin[4])) {
      case 0:
      case 1:
      case 2:
      case 3:
        setBinOrIin('iin');
        break;
      case 4:
      case 5:
      case 6:
        setBinOrIin('bin');
        break;
    }
  };

  useEffect(() => {
    const signXmlCallback = async (resp: Response) => {
      if (resp && resp.code && resp.code === '200') {
        await checkXml(resp.responseObject);
        return;
      }
    };

    ws.current!.onmessage = e => {
      if (e.data === '--heartbeat--') {
        return;
      }

      const data = JSON.parse(e.data);
      if (data !== null) {
        switch (state.method) {
          case 'signXml':
            signXmlCallback(data);
            break;
          default:
            // tslint:disable-next-line
            const payload = JSON.parse(e.data);
            break;
        }
      }
    };
  }, [state, setState]);

  const handleClose = () => {
    setOpen(false);
  };

  const changeReadOnly = (event: any) => {
    const readonly = event.currentTarget.previousElementSibling.readOnly;
    event.currentTarget.previousElementSibling.readOnly = !readonly;
  };

  const loginWithCert = async () => {
    if (ready) {
      setState({
        ...state,
        method: 'signXml',
      });
      const xmlToSign = await authService.getXmlToSign();
      const send = {
        args: ['PKCS12', 'AUTHENTICATION', xmlToSign, '', ''],
        method: 'signXml',
        module: 'kz.gov.pki.knca.commonUtils',
      };
      ws.current!.send(JSON.stringify(send));
    } else {
      tryConnect();
    }
  };

  const charts = '(.,:;?!*+%-<>@[]{ }/\\\\_{ }$#)';

  let initialData = {
    bin: state.registrationData.organizationalNumber,
    supplierName: state.registrationData.organizationName,
    chiefName: '',
    city: '',
    personName: '',
    supplierChanged: false,
    chiefChanged: false,
    employeeCount: 0,
    supplierHasChief: false,
    isChief: false,
    email: '',
    mask: '+7 (999) 999-99-99',
    phoneNumber: '',
    contactNumber: '',
    position: '',
    password: '',
    passwordConfirmation: '',
    policy: false,
    firstRegisterDate: new Date(),
    reRegisterDate: null,
    issuingAuthority: '',
    kindOfActivity: '',
    chiefBin: '',
    founderType: 'individual',
    firstChief: {
      firstUser: false,
      firstName: '',
      lastName: '',
      surname: '',
      name: getFieldData('Руководитель', state.fields).value,
      iin: '',
      email: '',
      phone: '',
    },
    legalAddress: {
      country: 'Казахстан',
      kato: state.kato,
      street: '',
      houseNumber: '',
      roomNumber: getFieldData('Юридический адрес', state.fields).value,
      region: getFieldData('Регион', state.fields).value,
      address: '',
    },
    actualAddress: {
      country: 'Казахстан',
      kato: '',
      street: '',
      houseNumber: '',
      roomNumber: '',
      address: '',
    },
    organizationData: {
      phone: '',
      email: '',
      site: '',
    },
    mainUser: {
      firstName: '',
      lastName: '',
      surname: '',
      iin: '',
      email: '',
      phone: '',
      mainUser: 'false',
      position: '',
      positionKZ: '',
      positionEN: '',
    },
    isLegalAddress: true,
    attachedDocs: {
      charter: null,
      directorAppointmentOrder: null,
      certificateOfStateReRegistration: null,
      certificateVATRegistration: null,
      licenseCopy: null,
      identityDocument: null,
      specialLicense: null,
    },
  };

  const handleSubmitSuccess = (email: string) => {
    if (onSubmitSuccess) {
      onSubmitSuccess(email);
    }
  };

  const openLicenseDialog = (event: any) => {
    event.preventDefault();
    setOpenLicence(true);
  };

  const closeLicenseDialog = (event: any) => {
    event.preventDefault();
    setOpenLicence(false);
  };

  return (
    <Box mt={2} className={classes.certificate}>
      {state.registrationData &&
        state.registrationData.success &&
        mode === 'login' &&
        `XML подписан: ${state.registrationData.organizationName}`}

      {selectedSupplier && Boolean(selectedSupplier.generalInfo.license) && (
        <LicenseDialog
          open={openLicence}
          closeLicenseDialog={closeLicenseDialog}
          license={selectedSupplier.generalInfo.license}
        />
      )}

      {state.registrationData && state.registrationData.success && mode === 'registration' && (
        <div className={classes.binIinWrap}>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            connector={<QontoConnector />}
            orientation="vertical"
          >
            {steps.map((label, key) => (
              <Step key={key}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Formik
            initialValues={initialData}
            validationSchema={Yup.object().shape({
              supplierName: Yup.string()
                .max(255)
                .required(translate(rest, 'app:Global;validation:required', 'Required')),
              bin: Yup.string()
                .max(255)
                .required(translate(rest, 'app:Global;validation:required', 'Required')),
              password: Yup.string()
                .min(12, translate(rest, 'app:Global;validation:minLengthPassword', ''))
                .max(255)
                .test('password', translate(rest, 'app:Global;validation:wrongPassword'), val => {
                  let count = 0;
                  count += /[a-z]/.test(val) ? 1 : 0;
                  count += /[A-Z]/.test(val) ? 1 : 0;
                  count += /\d/.test(val) ? 1 : 0;
                  count += /[\!\@\?\,\/\\\#\$\%\*\)\(\+\.\<\>\{\}\[\]\:\;\_\-]/g.test(val) ? 1 : 0;

                  if (count > 3) {
                    return true;
                  }
                  return false;
                })
                .required(translate(rest, 'app:Global;validation:required', 'Required')),
              passwordConfirmation: Yup.string()
                .min(12, translate(rest, 'app:Global;validation:minLengthPassword', ''))
                .max(255)
                .test('password', translate(rest, 'app:Global;validation:wrongPassword'), val => {
                  let count = 0;
                  count += /[a-z]/.test(val) ? 1 : 0;
                  count += /[A-Z]/.test(val) ? 1 : 0;
                  count += /\d/.test(val) ? 1 : 0;
                  count += /[\!\@\?\,\/\\\#\$\%\*\)\(\+\.\<\>\{\}\[\]\:\;\_\-]/g.test(val) ? 1 : 0;

                  if (count > 3) {
                    return true;
                  }
                  return false;
                })
                .required(translate(rest, 'app:Global;validation:required', 'Required'))
                .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать'),
              policy: Yup.boolean().oneOf([true]),
              organizationData: Yup.object().shape({
                phone: Yup.string().required(translate(rest, 'app:Global;validation:required', 'Required')),
                email: Yup.string()
                  .email(translate(rest, 'app:Global;validation:email', 'Must be valid email'))
                  .required(translate(rest, 'app:Global;validation:required', 'Required')),
              }),
              firstChief: Yup.object().shape({
                phone: Yup.string().required(translate(rest, 'app:Global;validation:required', 'Required')),
                email: Yup.string()
                  .email(translate(rest, 'app:Global;validation:email', 'Must be valid email'))
                  .required(translate(rest, 'app:Global;validation:required', 'Required')),
              }),
            })}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              try {
                let registerValues = { ...values };
                registerValues.email = values.firstChief.email;
                registerValues.phoneNumber = values.firstChief.phone;
                registerValues.personName = values.firstChief.name;
                registerValues.city = selectedSupplier.generalInfo.city;

                await authService.registration(registerValues);
                sessionStorage.setItem('registeredEmail', registerValues.email);
                supplierService.getCheckSupplier(selectedSupplier.generalInfo.bin, true);
                onSubmitSuccess && onSubmitSuccess(registerValues.email);
                // const message = `${ registerValues.personName } ${ translate(rest, 'app:Notifications;Registration:success', 'Registration success') }`;
                const message =
                  'Спасибо за регистрацию на Портале Электронных Закупок. Мы проверяем Ваши данные. Проверка может занять до 5 рабочих дней. Мы сообщим о результатах проверки';
                enqueueSnackbar(message, {
                  variant: 'success',
                  autoHideDuration: 10000,
                });
              } catch (error) {
                setStatus({ success: false });
                setSubmitting(false);
                if (close) {
                  close();
                }
                /*enqueueSnackbar(translate(rest, 'app:Notifications;Registration:error', 'Registration error'), {
                                variant: 'error',
                                autoHideDuration: 10000,
                            });*/
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              setErrors,
              setFieldTouched,
            }) => (
              <form className={clsx(classes.root, className)} onSubmit={handleSubmit} {...rest}>
                <Box>
                  {activeStep === 0 && (
                    <>
                      <Typography variant={'h4'}>Сведения о компании</Typography>
                      <Box className={classes.binBox}>
                        <TextField
                          error={Boolean(errors.bin)}
                          fullWidth
                          helperText={errors.bin}
                          label={translate(rest, 'pub;Registration;Form:bin/iin', 'BIN/IIN')}
                          margin="normal"
                          name="bin"
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.bin}
                          variant="outlined"
                        />
                      </Box>
                      <>
                        {selectedSupplier && (
                          <>
                            <TextField
                              error={Boolean(errors.supplierName)}
                              fullWidth
                              helperText={errors.supplierName}
                              label={'Наименование'}
                              margin="normal"
                              name="supplierName"
                              onBlur={handleBlur}
                              onChange={(event: any) => {
                                setFieldValue('supplierName', event.target.value);
                                setFieldValue('supplierChanged', true);
                              }}
                              value={values.supplierName}
                              variant="outlined"
                              required
                            />
                            <Box mt={2} mb={2}>
                              <Card className={classes.root} {...rest}>
                                <Divider />
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>БИН/ИИН</TableCell>
                                      <TableCell>ОКПО</TableCell>
                                      <TableCell>КАТО</TableCell>
                                      <TableCell>Регистрация</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && selectedSupplier.generalInfo.bin}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && selectedSupplier.generalInfo.okpo}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && selectedSupplier.generalInfo.kato}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && selectedSupplier.generalInfo.registerDate}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Card>
                            </Box>
                            <Box mt={2} mb={2}>
                              <Card className={classes.root} {...rest}>
                                <CardHeader title="Общая информация" />
                                <Divider />
                                <Table>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>Руководитель</TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && selectedSupplier.generalInfo.supervisor}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>КРП</TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && selectedSupplier.generalInfo.krp}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>ОКЭД</TableCell>
                                      <TableCell>
                                        {selectedSupplier && selectedSupplier.generalInfo.oked.oked ? (
                                          <>
                                            <Typography variant="body2" color="textSecondary">
                                              {selectedSupplier && selectedSupplier.generalInfo.oked.oked}
                                            </Typography>
                                            {selectedSupplier &&
                                              selectedSupplier.generalInfo.oked.fields.map(
                                                (item: any, index: number) => {
                                                  return (
                                                    <Typography key={index} variant="body2" color="textSecondary">
                                                      {item}
                                                    </Typography>
                                                  );
                                                }
                                              )}
                                          </>
                                        ) : (
                                          <Typography variant="body2" color="textSecondary">
                                            {selectedSupplier && selectedSupplier.generalInfo.oked}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Плательщик НДС</TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && selectedSupplier.generalInfo.VATPayer
                                            ? selectedSupplier.generalInfo.VATPayer.status === true
                                              ? `Да ${
                                                  selectedSupplier.generalInfo.VATPayer.creationTime
                                                    ? '(Проверено ' +
                                                      moment(selectedSupplier.generalInfo.VATPayer.creationTime).format(
                                                        'DD.MM.YYYY, HH:mm'
                                                      ) +
                                                      ')'
                                                    : ''
                                                }`
                                              : 'Нет'
                                            : 'Нет данных'}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Лицензии</TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                          {selectedSupplier && Boolean(selectedSupplier.generalInfo.license.length) && (
                                            <a
                                              href=""
                                              onClick={(event: any) => {
                                                openLicenseDialog(event);
                                              }}
                                            >
                                              Всего {selectedSupplier.generalInfo.license.length} лицензий
                                            </a>
                                          )}
                                          {selectedSupplier &&
                                            !Boolean(selectedSupplier.generalInfo.license.length) &&
                                            'Всего 0 лицензий'}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Card>
                            </Box>
                          </>
                        )}
                        {/*<Typography variant={ 'h4' }>
                                                Данные о регистрации
                                            </Typography>
                                            <Box mt={2}>
                                                <KeyboardDatePicker
                                                    className={classes.datePicker}
                                                    label="Дата первичной регистрации"
                                                    format="DD/MM/YYYY"
                                                    name="firstRegisterDate"
                                                    inputVariant="outlined"
                                                    value={values.firstRegisterDate}
                                                    onBlur={() => setFieldTouched('firstRegisterDate')}
                                                    onClose={() => setFieldTouched('firstRegisterDate')}
                                                    onAccept={() => setFieldTouched('firstRegisterDate')}
                                                    onChange={(date) => setFieldValue('firstRegisterDate', date)}
                                                />
                                                <KeyboardDatePicker
                                                    className={classes.datePicker}
                                                    label="Дата последней перерегистрации"
                                                    format="DD/MM/YYYY"
                                                    name="reRegisterDate"
                                                    inputVariant="outlined"
                                                    InputLabelProps={ { shrink: true } }
                                                    value={ values.reRegisterDate }
                                                    onBlur={ () => setFieldTouched('reRegisterDate') }
                                                    onClose={ () => setFieldTouched('reRegisterDate') }
                                                    onAccept={ () => setFieldTouched('reRegisterDate') }
                                                    onChange={ (date) => setFieldValue('reRegisterDate', date) }
                                                />
                                            </Box>
                                            <TextField
                                                required
                                                error={Boolean(touched.issuingAuthority && errors.issuingAuthority)}
                                                fullWidth
                                                helperText={touched.issuingAuthority && errors.issuingAuthority}
                                                label={"Орган выдачи свидетельства о регистрации"}
                                                margin="normal"
                                                name="issuingAuthority"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.issuingAuthority}
                                                variant="outlined"
                                            />
                                            <TextField
                                                error={Boolean(touched.kindOfActivity && errors.kindOfActivity)}
                                                fullWidth
                                                helperText={touched.kindOfActivity && errors.kindOfActivity}
                                                label={"Вид деятельности"}
                                                margin="normal"
                                                name="kindOfActivity"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.kindOfActivity}
                                                variant="outlined"
                                            />*/}
                      </>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Typography variant={'h4'}>Юридический адрес регистрации</Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isLegalAddress}
                            onChange={handleChange}
                            name="isLegalAddress"
                            color="primary"
                          />
                        }
                        label={'Юридический адрес указан верно'}
                      />
                      <Box mt={2} mb={2}>
                        <Grid container spacing={4}>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(errors.legalAddress && errors.legalAddress.country)}
                              fullWidth
                              helperText={errors.legalAddress && errors.legalAddress.country}
                              label={'Страна'}
                              name="legalAddress.country"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.legalAddress.country}
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            {/*<Box className={ classes.binBox }>
                                                        <TextField
                                                            error={Boolean(touched.legalAddress && touched.legalAddress.kato && errors.legalAddress && errors.legalAddress.kato)}
                                                            fullWidth
                                                            helperText={touched.legalAddress && touched.legalAddress.kato && errors.legalAddress && errors.legalAddress.kato}
                                                            label={'KATO'}
                                                            name="legalAddress.kato"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.legalAddress.kato}
                                                            variant="outlined"
                                                        />
                                                        <Button
                                                            className={clsx(classes.button, classes.katoButton)}
                                                            size="small"
                                                            onClick={() => {
                                                            }}
                                                            variant="contained"
                                                        >
                                                            {translate(rest, 'pub;Registration;Form:Search', 'Search')}
                                                        </Button>
                                                    </Box>*/}
                            <TextField
                              error={Boolean(errors.legalAddress && errors.legalAddress.kato)}
                              fullWidth
                              disabled={values.isLegalAddress}
                              helperText={errors.legalAddress && errors.legalAddress.kato}
                              label={'KATO'}
                              name="legalAddress.kato"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.legalAddress.kato}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <>
                            {!values.isLegalAddress && (
                              <>
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    error={Boolean(errors.legalAddress && errors.legalAddress.street)}
                                    fullWidth
                                    helperText={errors.legalAddress && errors.legalAddress.street}
                                    label={'Индекс'}
                                    name="legalAddress.street"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                    value={values.legalAddress.street}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    error={Boolean(errors.legalAddress && errors.legalAddress.houseNumber)}
                                    fullWidth
                                    helperText={errors.legalAddress && errors.legalAddress.houseNumber}
                                    label={'Город'}
                                    name="legalAddress.houseNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                    value={values.legalAddress.houseNumber}
                                    variant="outlined"
                                  />
                                </Grid>
                              </>
                            )}
                            {values.isLegalAddress && (
                              <>
                                <Grid item md={4} xs={12}>
                                  <TextField
                                    error={Boolean(
                                      touched.legalAddress &&
                                        touched.legalAddress.region &&
                                        errors.legalAddress &&
                                        errors.legalAddress.region
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.legalAddress &&
                                      touched.legalAddress.region &&
                                      errors.legalAddress &&
                                      errors.legalAddress.region
                                    }
                                    label={'Регион'}
                                    disabled={values.isLegalAddress}
                                    name="legalAddress.region"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                    value={values.legalAddress.region}
                                    variant="outlined"
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.legalAddress &&
                                  touched.legalAddress.roomNumber &&
                                  errors.legalAddress &&
                                  errors.legalAddress.roomNumber
                              )}
                              fullWidth
                              disabled={values.isLegalAddress}
                              helperText={
                                touched.legalAddress &&
                                touched.legalAddress.roomNumber &&
                                errors.legalAddress &&
                                errors.legalAddress.roomNumber
                              }
                              label={'Юридический адрес'}
                              name="legalAddress.roomNumber"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.legalAddress.roomNumber}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      {/*{ !values.isLegalAddress && <>
                                            <Typography variant={ 'h4' }>
                                                Фактический адрес местонахождения
                                            </Typography>
                                            <Box mt={2} mb={2}>
                                                <Grid
                                                    container
                                                    spacing={4}
                                                >
                                                    <Grid
                                                        item
                                                        md={4}
                                                        xs={12}
                                                    >
                                                        <TextField
                                                            error={Boolean(touched.actualAddress && touched.actualAddress.country && errors.actualAddress && errors.actualAddress.country)}
                                                            fullWidth
                                                            helperText={touched.actualAddress && touched.actualAddress.country && errors.actualAddress && errors.actualAddress.country}
                                                            label={'Страна'}
                                                            name="actualAddress.country"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.actualAddress.country}
                                                            variant="outlined"
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        xs={12}
                                                    >
                                                        <Box className={ classes.binBox }>
                                                            <TextField
                                                                error={Boolean(touched.actualAddress && touched.actualAddress.kato && errors.actualAddress && errors.actualAddress.kato)}
                                                                fullWidth
                                                                helperText={touched.actualAddress && touched.actualAddress.kato && errors.actualAddress && errors.actualAddress.kato}
                                                                label={'KATO'}
                                                                name="actualAddress.kato"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.actualAddress.kato}
                                                                variant="outlined"
                                                            />
                                                            <Button
                                                                className={clsx(classes.button, classes.katoButton)}
                                                                size="small"
                                                                onClick={() => {
                                                                }}
                                                                variant="contained"
                                                            >
                                                                {translate(rest, 'pub;Registration;Form:Search', 'Search')}
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        xs={12}
                                                    >
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={4}
                                                >
                                                    <Grid
                                                        item
                                                        md={4}
                                                        xs={12}
                                                    >
                                                        <TextField
                                                            error={Boolean(touched.actualAddress && touched.actualAddress.street && errors.actualAddress && errors.actualAddress.street)}
                                                            fullWidth
                                                            helperText={touched.actualAddress && touched.actualAddress.street && errors.actualAddress && errors.actualAddress.street}
                                                            label={'Индекс'}
                                                            name="actualAddress.street"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.actualAddress.street}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        xs={12}
                                                    >
                                                        <TextField
                                                            error={Boolean(touched.actualAddress && touched.actualAddress.houseNumber && errors.actualAddress && errors.actualAddress.houseNumber)}
                                                            fullWidth
                                                            helperText={touched.actualAddress && touched.actualAddress.houseNumber && errors.actualAddress && errors.actualAddress.houseNumber}
                                                            label={'Город'}
                                                            name="actualAddress.houseNumber"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.actualAddress.houseNumber}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        xs={12}
                                                    >
                                                        <TextField
                                                            error={Boolean(touched.actualAddress && touched.actualAddress.roomNumber && errors.actualAddress && errors.actualAddress.roomNumber)}
                                                            fullWidth
                                                            helperText={touched.actualAddress && touched.actualAddress.roomNumber && errors.actualAddress && errors.actualAddress.roomNumber}
                                                            label={'Юридический адрес'}
                                                            name="actualAddress.roomNumber"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.actualAddress.roomNumber}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                        }*/}
                      <Typography variant={'h4'}>Контакты организации</Typography>
                      <Box mt={2}>
                        <Grid container spacing={4}>
                          <Grid item md={4} xs={12}>
                            <InputMask
                              className={clsx(
                                classes.phoneNumber,
                                Boolean(errors.organizationData && errors.organizationData.phone) ? classes.error : ''
                              )}
                              mask={'+7 999 999-99-99'}
                              value={values.organizationData.phone}
                              onChange={handleChange}
                              name="organizationData.phone"
                              placeholder={'Контактный телефон *'}
                              onBlur={handleBlur}
                            />
                            {Boolean(errors.organizationData && errors.organizationData.phone) && (
                              <FormHelperText className={classes.helperText} error>
                                {errors.organizationData && errors.organizationData.phone}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(errors.organizationData && errors.organizationData.email)}
                              fullWidth
                              helperText={errors.organizationData && errors.organizationData.email}
                              label={'Электронная почта'}
                              name="organizationData.email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.organizationData.email}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.organizationData &&
                                  touched.organizationData.site &&
                                  errors.organizationData &&
                                  errors.organizationData.site
                              )}
                              fullWidth
                              helperText={
                                touched.organizationData &&
                                touched.organizationData.site &&
                                errors.organizationData &&
                                errors.organizationData.site
                              }
                              label={'Сайт'}
                              name="organizationData.site"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.organizationData.site}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Typography variant={'h4'}>Учредители</Typography>
                      <Box mt={2}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="founderType">Тип учредителя</InputLabel>
                          <Select
                            disabled={binOrIin === 'iin'}
                            native
                            value={values.founderType}
                            onChange={handleChange}
                            label="Тип учредителя"
                            inputProps={{
                              name: 'founderType',
                              id: 'founderType',
                            }}
                          >
                            <option value={'individual'}>Физическое лицо</option>
                            <option value={'entity'}>Юридическое лицо</option>
                          </Select>
                        </FormControl>
                        <TextField
                          disabled={binOrIin === 'iin'}
                          error={Boolean(touched.chiefBin && errors.chiefBin)}
                          fullWidth
                          helperText={touched.chiefBin && errors.chiefBin}
                          label={'БИН/ИИН'}
                          margin="normal"
                          name="chiefBin"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.chiefBin}
                          variant="outlined"
                        />
                        <TextField
                          disabled={binOrIin === 'iin'}
                          error={Boolean(touched.chiefName && errors.chiefName)}
                          fullWidth
                          helperText={touched.chiefName && errors.chiefName}
                          label={'Полное наименование'}
                          margin="normal"
                          name="chiefName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.chiefName}
                          variant="outlined"
                        />
                      </Box>
                    </>
                  )}
                  {activeStep === 3 && (
                    <>
                      <Typography variant={'h4'}>Руководитель</Typography>
                      <Box mt={2} className={classes.firstChief}>
                        <TextField
                          error={Boolean(errors.firstChief && errors.firstChief.name)}
                          fullWidth
                          helperText={errors.firstChief && errors.firstChief.name}
                          label={'ФИО'}
                          disabled
                          margin="normal"
                          name="firstChief.name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.firstChief.name}
                          variant="outlined"
                        />
                        {/*<Grid
                                                container
                                                spacing={4}
                                            >
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        error={Boolean(touched.firstChief && touched.firstChief.lastName && errors.firstChief && errors.firstChief.lastName)}
                                                        fullWidth
                                                        helperText={touched.firstChief && touched.firstChief.lastName && errors.firstChief && errors.firstChief.lastName}
                                                        label={"Фамилия*"}
                                                        margin="normal"
                                                        name="firstChief.lastName"
                                                        onBlur={handleBlur}
                                                        onChange={(event: any) => {
                                                            setFieldValue('firstChief.lastName', event.target.value);
                                                            setFieldValue('firstChiefChanged', true);
                                                        }}
                                                        value={values.firstChief.lastName}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        error={Boolean(touched.firstChief && touched.firstChief.firstName && errors.firstChief && errors.firstChief.firstName)}
                                                        fullWidth
                                                        helperText={touched.firstChief && touched.firstChief.firstName && errors.firstChief && errors.firstChief.firstName}
                                                        label={"Имя*"}
                                                        margin="normal"
                                                        name="firstChief.firstName"
                                                        onBlur={handleBlur}
                                                        onChange={(event: any) => {
                                                            setFieldValue('firstChief.firstName', event.target.value);
                                                            setFieldValue('firstChiefChanged', true);
                                                        }}
                                                        value={values.firstChief.firstName}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        error={Boolean(touched.firstChief && touched.firstChief.surname && errors.firstChief && errors.firstChief.surname)}
                                                        fullWidth
                                                        helperText={touched.firstChief && touched.firstChief.surname && errors.firstChief && errors.firstChief.surname}
                                                        label={"Отчество"}
                                                        margin="normal"
                                                        name="firstChief.surname"
                                                        onBlur={handleBlur}
                                                        onChange={(event: any) => {
                                                            setFieldValue('firstChief.surname', event.target.value);
                                                            setFieldValue('firstChiefChanged', true);
                                                        }}
                                                        value={values.firstChief.surname}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>*/}
                      </Box>
                      <TextField
                        error={Boolean(errors.firstChief && errors.firstChief.email)}
                        fullWidth
                        helperText={
                          (errors.firstChief && errors.firstChief.email) || 'Будет использоваться для входа в портал'
                        }
                        label={translate(rest, 'pub;Registration;Form:email')}
                        margin="normal"
                        name="firstChief.email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstChief.email}
                        variant="outlined"
                      />
                      <InputMask
                        className={clsx(
                          classes.phoneNumber,
                          Boolean(errors.firstChief && errors.firstChief.phone) ? classes.error : ''
                        )}
                        mask={values.mask}
                        value={values.firstChief.phone}
                        onChange={handleChange}
                        name="firstChief.phone"
                        placeholder={'Контактный телефон *'}
                        onBlur={handleBlur}
                      />
                      {Boolean(errors.firstChief && errors.firstChief.phone) && (
                        <FormHelperText className={classes.helperText} error>
                          {errors.firstChief && errors.firstChief.phone}
                        </FormHelperText>
                      )}
                      <Box className={classes.passwordBox}>
                        <Grid container spacing={4}>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(errors.password)}
                              fullWidth
                              helperText={errors.password}
                              label={translate(rest, 'pub;Registration;Form:password')}
                              margin="normal"
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="password"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - пароль должен состоять из букв латинского алфавита (A-z);
                                            </Typography>
                                          }
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - минимальная длина пароля 12 символов;
                                            </Typography>
                                          }
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - буквенная часть пароля должна содержать как строчные, так и прописные
                                              (заглавные) буквы - использование символов верхнего и нижнего регистров;
                                            </Typography>
                                          }
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - использование специальных символов {charts} и цифр (0-9);
                                            </Typography>
                                          }
                                        </React.Fragment>
                                      }
                                    >
                                      <HelpIcon />
                                    </Tooltip>
                                  </>
                                ),
                              }}
                              value={values.password}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(errors.passwordConfirmation)}
                              fullWidth
                              helperText={errors.passwordConfirmation}
                              label={'Подтверждение пароля*'}
                              margin="normal"
                              name="passwordConfirmation"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="password"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - пароль должен состоять из букв латинского алфавита (A-z);
                                            </Typography>
                                          }
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - минимальная длина пароля 12 символов;
                                            </Typography>
                                          }
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - буквенная часть пароля должна содержать как строчные, так и прописные
                                              (заглавные) буквы - использование символов верхнего и нижнего регистров;
                                            </Typography>
                                          }
                                          {
                                            <Typography variant="body2" className={classes.helpText}>
                                              - использование специальных символов {charts} и цифр (0-9);
                                            </Typography>
                                          }
                                        </React.Fragment>
                                      }
                                    >
                                      <HelpIcon />
                                    </Tooltip>
                                  </>
                                ),
                              }}
                              value={values.passwordConfirmation}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}></Grid>
                        </Grid>
                      </Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={needAdditionalUser}
                            onChange={needAdditionalUserChange}
                            name="needAdditionalUser"
                            color="primary"
                          />
                        }
                        label={'Желаете добавить дополнительного пользователя?'}
                      />
                    </>
                  )}
                  {activeStep === 4 && (
                    <>
                      <Typography variant={'h4'}>Дополнительный пользователь</Typography>
                      <Box mt={2}>
                        <Grid container spacing={4}>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.mainUser &&
                                  touched.mainUser.lastName &&
                                  errors.mainUser &&
                                  errors.mainUser.lastName
                              )}
                              fullWidth
                              helperText={
                                touched.mainUser &&
                                touched.mainUser.lastName &&
                                errors.mainUser &&
                                errors.mainUser.lastName
                              }
                              label={'Фамилия'}
                              name="mainUser.lastName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mainUser.lastName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.mainUser &&
                                  touched.mainUser.firstName &&
                                  errors.mainUser &&
                                  errors.mainUser.firstName
                              )}
                              fullWidth
                              helperText={
                                touched.mainUser &&
                                touched.mainUser.firstName &&
                                errors.mainUser &&
                                errors.mainUser.firstName
                              }
                              label={'Имя'}
                              name="mainUser.firstName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mainUser.firstName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.mainUser &&
                                  touched.mainUser.surname &&
                                  errors.mainUser &&
                                  errors.mainUser.surname
                              )}
                              fullWidth
                              helperText={
                                touched.mainUser &&
                                touched.mainUser.surname &&
                                errors.mainUser &&
                                errors.mainUser.surname
                              }
                              label={'Отчество'}
                              name="mainUser.surname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mainUser.surname}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.mainUser && touched.mainUser.iin && errors.mainUser && errors.mainUser.iin
                              )}
                              fullWidth
                              helperText={
                                touched.mainUser && touched.mainUser.iin && errors.mainUser && errors.mainUser.iin
                              }
                              label={'ИИН'}
                              name="mainUser.iin"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mainUser.iin}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(
                                touched.mainUser && touched.mainUser.email && errors.mainUser && errors.mainUser.email
                              )}
                              fullWidth
                              helperText={
                                touched.mainUser && touched.mainUser.email && errors.mainUser && errors.mainUser.email
                              }
                              label={'Электронная почта'}
                              name="mainUser.email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mainUser.email}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <InputMask
                              className={clsx(
                                classes.phoneNumber,
                                Boolean(
                                  touched.mainUser && touched.mainUser.phone && errors.mainUser && errors.mainUser.phone
                                )
                                  ? classes.error
                                  : ''
                              )}
                              mask={'+7 999 999-99-99'}
                              value={values.mainUser.phone}
                              onChange={handleChange}
                              name="mainUser.phone"
                              placeholder={'Контактный телефон'}
                              onBlur={handleBlur}
                            />
                            {Boolean(
                              touched.mainUser && touched.mainUser.phone && errors.mainUser && errors.mainUser.phone
                            ) && (
                              <FormHelperText className={classes.helperText} error>
                                {errors.mainUser && errors.mainUser.phone}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                        <TextField
                          error={Boolean(
                            touched.mainUser && touched.mainUser.position && errors.mainUser && errors.mainUser.position
                          )}
                          fullWidth
                          helperText={
                            touched.mainUser && touched.mainUser.position && errors.mainUser && errors.mainUser.position
                          }
                          label={'Должность'}
                          margin="normal"
                          name="mainUser.position"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.mainUser.position}
                          variant="outlined"
                        />
                      </Box>
                    </>
                  )}
                  {activeStep === 5 && (
                    <>
                      <Box>
                        <NewFileItem
                          title={'Устав*'}
                          data={values.attachedDocs.charter}
                          required={true}
                          showErrors={Boolean(errors.policy)}
                          setFileToForm={(file: any) => {
                            setFieldValue('attachedDocs.charter', file);
                          }}
                        />
                        {binOrIin === 'bin' && (
                          <NewFileItem
                            title={'Приказ о назначении руководителя*'}
                            required={true}
                            data={values.attachedDocs.directorAppointmentOrder}
                            showErrors={Boolean(errors.policy)}
                            setFileToForm={(file: any) => {
                              setFieldValue('attachedDocs.directorAppointmentOrder', file);
                            }}
                          />
                        )}
                        <NewFileItem
                          title={'Справка, свидетельство о гос. регистрации (перерегистрации)*'}
                          required={true}
                          data={values.attachedDocs.certificateOfStateReRegistration}
                          showErrors={Boolean(errors.policy)}
                          setFileToForm={(file: any) => {
                            setFieldValue('attachedDocs.certificateOfStateReRegistration', file);
                          }}
                        />
                        <NewFileItem
                          title={'Свид-во о постановке на учет по НДС*'}
                          required={true}
                          data={values.attachedDocs.certificateVATRegistration}
                          showErrors={Boolean(errors.policy)}
                          setFileToForm={(file: any) => {
                            setFieldValue('attachedDocs.certificateVATRegistration', file);
                          }}
                        />
                        <NewFileItem
                          title={'Специальная лицензия'}
                          data={values.attachedDocs.specialLicense}
                          setFileToForm={(file: any) => {
                            setFieldValue('attachedDocs.specialLicense', file);
                          }}
                        />
                        {binOrIin === 'iin' && (
                          <>
                            <NewFileItem
                              title={'Копия лицензии*'}
                              required={true}
                              data={values.attachedDocs.licenseCopy}
                              showErrors={Boolean(errors.policy)}
                              setFileToForm={(file: any) => {
                                setFieldValue('attachedDocs.licenseCopy', file);
                              }}
                            />
                            <NewFileItem
                              title={'Документ, удостоверяющий личность*'}
                              required={true}
                              showErrors={Boolean(errors.policy)}
                              data={values.attachedDocs.identityDocument}
                              setFileToForm={(file: any) => {
                                setFieldValue('attachedDocs.identityDocument', file);
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </>
                  )}
                  {activeStep === 6 && (
                    <>
                      <Box>
                        <Typography variant="h4" color="textPrimary">
                          <strong>{translate(rest, 'pub;Registration;Form:policy:header')}</strong>
                        </Typography>
                      </Box>
                      <Box className={classes.policy}>
                        <div>
                          <p>
                            Настоящее Пользовательское соглашение регулирует отношения в отношении интернет-ресурса
                            &ndash; Портала электронных закупок АО &laquo;УКТМК&raquo; (далее &ndash;
                            &laquo;Портал&raquo;) между Собственником Портала и Пользователем на нижеследующих условиях:
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            Текст и содержание Пользовательского соглашения могут быть изменены Собственником в любое
                            время без какого-либо специального уведомления Пользователя, при этом новая редакция
                            Пользовательского соглашения вступает в силу с момента ее размещения Собственником на
                            Портале по адресу: https://e-procurement.uktmp.kz, если Собственником прямо не будет указано
                            иное в отдельном сопроводительном сообщении.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            Регулярное ознакомление с действующей редакцией Пользовательского соглашения является
                            обязанностью Пользователя.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            Использование Пользователем Портала после вступления в силу новой редакции Пользовательского
                            соглашения означает полное согласие Пользователя и применение к отношениям между
                            Пользователем и Собственником положений Пользовательского соглашения в новой действующей
                            редакции.
                          </p>
                          <p>&nbsp;</p>
                          <ol>
                            <strong>
                              <li>1. Термины и определения</li>
                            </strong>
                          </ol>
                          <p>
                            1.1. Пользователь &ndash; посетитель интернет-ресурсов сети Internet, в том числе Портала.
                          </p>
                          <p>
                            1.2. Портал &ndash; интернет-ресурс, расположенный по адресу: https://e-procurement.uktmp.kz
                            и представляющий собой совокупность содержащихся в информационной системе информации и
                            объектов интеллектуальной собственности (в том числе, программа для ЭВМ, база данных,
                            графическое оформление интерфейса (дизайн) и др.).
                          </p>
                          <p>
                            1.3. Собственник Портала &ndash; АО &laquo;Усть-Каменогорский титано-магниевый
                            комбинат&raquo;.
                          </p>
                          <p>&nbsp;</p>
                          <ol start={2}>
                            <strong>
                              <li>2. Общие положения</li>
                            </strong>
                          </ol>
                          <p>
                            2.1. Собственник предлагает Пользователю на условиях настоящего Пользовательского соглашения
                            воспользоваться информацией, ресурсами и сервисом Портала.
                          </p>
                          <p>
                            2.2. Информация, ресурсы и сервис Портала предоставляются Пользователю бесплатно, если на
                            Портале не указано иное.
                          </p>
                          <p>
                            2.3. Предоставление отдельной информации, ресурсов и сервисов Портала могут регулироваться
                            специальными правилами и/или соглашениями, являющимися неотъемлемой частью настоящего
                            Пользовательского соглашения и приведенными на Портале.
                          </p>
                          <p>
                            2.4. Независимо от факта регистрации или авторизации Пользователя на Портале, использование
                            Пользователем Портала, включая просмотр размещенной на Портале информации, означает полное
                            согласие Пользователя с настоящим Пользовательским соглашением и принятие им на себя
                            обязательств следовать инструкциям по использованию Портала, а также предусмотренной в
                            отношении Пользователя ответственности.
                          </p>
                          <p>
                            2.5. Осуществляя доступ к Порталу и заключая настоящее Пользовательское соглашение,
                            Пользователь гарантирует, что обладает всеми правами и полномочиями, необходимыми для
                            заключения и исполнения Пользовательского соглашения согласно действующего законодательства
                            Республики Казахстан.
                          </p>
                          <p>&nbsp;</p>
                          <ol start={3}>
                            <strong>
                              <li>3. Сведения, предоставляемые Пользователями</li>
                            </strong>
                          </ol>
                          <p>
                            3.1. В рамках использования Портала Пользователь обязуется предоставлять только достоверные
                            сведения и самостоятельно несет полную ответственность за такую предоставленную информацию.
                            Пользователь обязуется своевременно актуализировать данные сведения.
                          </p>
                          <p>3.2. В процессе пользования Порталом Пользователь самостоятельно и добровольно:</p>
                          <p>
                            3.2.1. принимает решение о предоставлении Собственнику или размещении в открытом доступе
                            своих персональных и иных сведений с учетом Политики конфиденциальности и обработки
                            персональных данных, актуальный текст которой расположен по адресу:
                            https://e-procurement.uktmp.kz&nbsp; (далее &ndash; &laquo;Политика&raquo;);
                          </p>
                          <p>
                            3.2.2. заявляет о своем согласии на обработку Порталом персональных и иных данных
                            Пользователя, в том числе для целей: предоставления консультационной поддержки Пользователям
                            в связи с использованием Портала; доставки сообщений другим Пользователям; получения
                            статистических и аналитических данных для улучшения функционирования Портала; получения
                            информационных и/или рекламных сообщений от Портала, в том числе в виде коротких сообщений
                            (sms-сообщений) на номера мобильных телефонов и e-mail писем на адреса электронной почты,
                            указанные Пользователем или его представителем, или размещенных с помощью Портала, либо в
                            виде уведомлений с помощью Портала, а также в виде интерактивных графиков, детализированных
                            отчетов и иных аналитических отчетов; предупреждения или пресечения незаконных и/или
                            несанкционированных действий Пользователей; обеспечения соблюдения требований действующего
                            законодательства Республики Казахстан.
                          </p>
                          <p>
                            3.3. Собственник Портала принимает все необходимые меры для защиты персональных данных
                            Пользователя от несанкционированного доступа третьих лиц в соответствии с Политикой.
                          </p>
                          <p>
                            3.4. Собственник Портала не оказывает Пользователю консультаций по вопросам, не относящимся
                            к Порталу.
                          </p>
                          <p>3.5. Пользователь соглашается:</p>
                          <p>3.5.1. на сбор Порталом обезличенных статистических данных о Пользователе;</p>
                          <p>
                            3.5.2. на передачу Собственником Портала третьим лицам обезличенных статистических данных, в
                            том числе для целей их обработки, для обеспечения функционирования Портала, реализации
                            партнерских и иных программ Портала, при условии соблюдения Собственником Портала условий,
                            отраженных в Политике.
                          </p>
                          <p>&nbsp;</p>
                          <ol start={4}>
                            <strong>
                              <li>4. Обязательства Пользователя</li>
                            </strong>
                          </ol>
                          <p>
                            4.1. Пользователь обязуется действовать исключительно в соответствии с действующим
                            законодательством и настоящим Пользовательским соглашением, а также нести в соответствии с
                            законодательством Республики Казахстан полную ответственность за собственные действия и
                            бездействие на Портале.
                          </p>
                          <p>
                            4.2. Портал может быть использован Пользователем исключительно в целях, для которых данный
                            Портал и предназначен.
                          </p>
                          <p>
                            4.3. Пользователь также обязуется не копировать, не воспроизводить, не изменять, не
                            распространять и не представлять общественности любую информацию, содержащуюся на Портале
                            (кроме сведений, предоставленных самим Пользователем) без предварительного письменного
                            разрешения от Собственника Портала и любой третьей стороны (если информация также
                            затрагивает интересы данных третьих лиц).
                          </p>
                          <p>
                            4.4. В целях пресечения или предотвращения нарушения Пользовательского соглашения и/или
                            причинения ущерба Порталу (например, DDoS-атаки или иные хакерские атаки, неавторизованное
                            использование программных средств и прочее), Собственник вправе ограничивать доступ
                            Пользователей к Порталу путем блокирования доступа с соответствующего ip-адреса или
                            диапазона ip-адресов.
                          </p>
                          <p>
                            4.5. Осуществляя доступ к Порталу, Пользователь выражает свое согласие на получение
                            рекламной информации, размещенной на Портале третьими лицами. Пользователь понимает и
                            соглашается, что Портал не определяет содержание и не несет ответственности за такую
                            информацию, включая сайты, ссылки на которые могут содержаться в соответствующих материалах.
                          </p>
                          <p>&nbsp;</p>
                          <ol start={5}>
                            <strong>
                              <li>5. Гарантии и ответственность</li>
                            </strong>
                          </ol>
                          <p>
                            5.1. Пользователь несет ответственность за действия, совершаемые на Портале, в соответствии
                            с действующим законодательством Республики Казахстан.
                          </p>
                          <p>
                            5.2. Портал и Собственник Портал не несут ответственности за потерю информации
                            Пользователем, а также за искажение информации или потерю сообщения, получаемого с
                            использованием форм связи на Портале.
                          </p>
                          <p>
                            5.3. Портал и Собственник Портала не несут ответственности за неисполнение или затруднения в
                            исполнении обязательств вследствие действия обстоятельств непреодолимой силы, последствия
                            которых невозможно избежать или преодолеть.
                          </p>
                          <p>&nbsp;</p>
                          <ol start={6}>
                            <strong>
                              <li>6. Срок действия Пользовательского соглашения</li>
                            </strong>
                          </ol>
                          <p>
                            6.1. Настоящее Пользовательское соглашение вступает в силу с момента начала использования
                            Пользователем Портала, независимо от факта регистрации Пользователя, и действует бессрочно.
                          </p>
                          <p>
                            6.2. Пользователь имеет право прекратить доступ к своему Личному кабинету без возможности
                            восстановления такого доступа.
                          </p>
                          <p>
                            6.3. Собственник Портала оставляет за собой право по собственному усмотрению прекратить
                            доступ Пользователя, нарушающего настоящее Пользовательское соглашение, иных правил,
                            регламентирующих функционирование Портала, в том числе прекратить или временно приостановить
                            доступ Пользователя в Личный кабинет. Пользователь не имеет права использовать для доступа
                            на Портал учетные данные другого Пользователя.
                          </p>
                          <p>&nbsp;</p>
                          <ol start={7}>
                            <strong>
                              <li>7. Споры и действующее законодательство</li>
                            </strong>
                          </ol>
                          <p>
                            7.1. При разрешении всех споров по настоящему Пользовательскому соглашению применяется
                            действующее законодательство Республики Казахстан.
                          </p>
                          <p>
                            7.2. Ввиду безвозмездности использования Портала, положения законодательства о защите прав
                            потребителей не подлежат применению к отношениям между Пользователем и Собственником
                            Портала.
                          </p>
                          <p>
                            7.3. Все споры, возникшие в рамках настоящего Соглашения, разрешаются Сторонами проведением
                            переговоров. В случае невозможности урегулировать спор путем проведения переговоров, споры
                            разрешаются в судебном порядке, предусмотренном действующим законодательством Республики
                            Казахстан, по месту учреждения Собственника Портала.
                          </p>
                          <p>
                            7.4. Признание отдельных частей настоящего Пользовательского соглашения недействительными не
                            отменяет действие других положений настоящего Пользовательского соглашения.
                          </p>
                        </div>
                        <Box alignItems="center" display="flex" mt={2} ml={-1}>
                          <Checkbox checked={values.policy} name="policy" onChange={handleChange} />
                          <Typography variant="body2" color="textSecondary">
                            {translate(rest, 'pub;Registration;Form:policy')}
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={2} display="flex">
                        <Button
                          className={classes.button}
                          disabled={isSubmitting || !values.policy}
                          size="large"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            setPolicySign(true);
                          }}
                        >
                          Подписать введенные данные
                        </Button>
                        <Box flexGrow={1} />
                        <Button
                          className={classes.button}
                          disabled={isSubmitting || !values.policy || !policySign}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {translate(rest, 'pub;Registration;Form;button:register')}
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
                <Box>
                  <Box className={classes.stepperActions}>
                    {activeStep === 0 ? (
                      <div></div>
                    ) : (
                      <Button className={classes.button} onClick={handleBack}>
                        Назад
                      </Button>
                    )}
                    {activeStep === steps.length - 1 ? (
                      <div></div>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleNext(values, setErrors);
                        }}
                        className={classes.button}
                      >
                        {'Дальше'}
                      </Button>
                    )}
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body2" color="textSecondary">
                      * - Поля, обязательные для заполнения
                    </Typography>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </div>
      )}
      {state.registrationData && !state.registrationData.success && (
        <Box className={classes.buttonCenter}>
          <Button variant="outlined" className={classes.button} onClick={loginWithCert}>
            <FormattedMessage
              id="pub:Login;digitalSignature;chooseCertificate:label"
              defaultMessage="Choose Certificate"
            />
          </Button>
        </Box>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <span className={classes.modalTitle}>
            {translate(rest, 'pub:Login;digitalSignature;errorNCALayer:label', 'Error NCALayer')}
          </span>
        </DialogTitle>
        <DialogContent>
          <Typography color="textPrimary" variant="h5">
            <FormattedMessage
              id="pub:Login;digitalSignature;errorNCALayer:description"
              defaultMessage="Error NCALayer"
            />
          </Typography>
          <Link
            className={classes.link}
            variant="h5"
            target="_blank"
            rel="noreferrer"
            href="https://pki.gov.kz/docs/nl_ru/windows/"
          >
            <FormattedMessage id="pub:Login;digitalSignature;NCALayer:link" defaultMessage="Link" />
          </Link>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            <FormattedMessage id="pub:Login;digitalSignature;button:close" defaultMessage="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default injectIntl(DigitalSignatureView);
