import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Button, Link, TextField, Typography, makeStyles, FormHelperText, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { login } from '../../../actions/public/authActions';
import { translate } from '../../../utils/i18nHelpers';
import { useDispatch } from 'react-redux';
import DigitalSignatureView from '../../../publicViews/auth/DigitalSignatureView';
import clsx from 'clsx';
import { getFolderList } from '../../../actions/public/folderActions';
import { setRequestedDocs } from '../../../actions/utilsActions';

const useStyles = makeStyles(() => ({
  root: {},
  registrationForm: {
    marginBottom: 8,
    background: 'linear-gradient(180deg,#01018a 0,#060656)',
    padding: 20,
    borderRadius: '0 0 15px 15px',
  },
  registrationHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
  },
  submitBtn: {
    backgroundColor: 'white',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000099',
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  stepper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    zIndex: 9,
    transitionDuration: '0.2s',
  },
  button: {
    backgroundColor: '#fff',
    color: '#000078',
    '&:hover': {
      backgroundColor: '#ffffffd6',
    },
  },
  slide: {
    transform: 'translate(280px)',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  tabItem: {
    textTransform: 'none',
    color: 'white',
    fontFamily: 'SegoeUI-Bold!important',
  },
  selectedTab: {
    borderBottom: '1px solid #fff',
    borderRadius: 0,
  },
  forgotPassword: {
    color: '#fff',
  },
}));

const CustomInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& label': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: '#fff',
      },
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(TextField);

interface Result {
  title: string;
  description: string;
}

function Login({ ...rest }: WrappedComponentProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('loginPassword');
  const history = useHistory();
  const tabs = [
    { value: 'loginPassword', defaultLabel: 'Login/Password' },
    { value: 'digitalSignature', defaultLabel: 'Digital Signature' },
  ];

  const handleTabsChange = (value: string) => {
    setCurrentTab(value);
  };

  const getTabTranslation = (tabKey: string, defaultLabel: string) => {
    return translate(rest, `pub:Login;Tabs;${tabKey}:label`, defaultLabel);
  };

  let initialValues = {
    email: '',
    password: '',
    submit: '',
  };

  return (
    <>
      <Box className={classes.registrationForm}>
        <div className={classes.tabs}>
          {tabs.map(tab => (
            <Button
              className={clsx(classes.tabItem, tab.value === currentTab && classes.selectedTab)}
              key={tab.value}
              onClick={() => {
                handleTabsChange(tab.value);
              }}
            >
              {getTabTranslation(tab.value, tab.defaultLabel)}
            </Button>
          ))}
        </div>
        {currentTab === 'loginPassword' && (
          <>
            <Typography className={classes.registrationHeader} variant={'h4'}>
              <PersonOutlineIcon />
              <FormattedMessage id="pub;Auth;title" defaultMessage="Authorization" />
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setStatus, setSubmitting, setErrors }) => {
                try {
                  await dispatch(login(values.email, values.password, false));
                  await dispatch(getFolderList());
                  dispatch(setRequestedDocs({ isModalOpen: true }));
                  history.push('/dashboard');
                } catch (error) {
                  const messageId = error.data && error.data.message;
                  const message = messageId
                    ? translate(rest, `app:loginView;signIn;Error:${messageId}`)
                    : 'Something went wrong';
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate className={classes.root} onSubmit={handleSubmit} {...rest}>
                  <CustomInput
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={<FormattedMessage id="app:loginView;signIn;login" defaultMessage="Login" />}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />
                  <CustomInput
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={<FormattedMessage id="app:loginView;signIn;password" defaultMessage="Password" />}
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box display="flex" justifyContent="space-between" alignItems="center" pl="2px" pr="2px" mt={2}>
                    <Button
                      className={classes.button}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      <FormattedMessage id="app:loginView;signIn;button" defaultMessage="Log In" />
                    </Button>
                    <Link
                      component={RouterLink}
                      to="/forgotPassword"
                      variant="subtitle1"
                      className={classes.forgotPassword}
                    >
                      <FormattedMessage id="app:loginView;forgotPassword;title" defaultMessage="Forgot Password?" />
                    </Link>
                  </Box>
                  <Box mt={2}>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </>
        )}
        {currentTab === 'digitalSignature' && <DigitalSignatureView mode={'login'} />}
      </Box>
    </>
  );
}

export default injectIntl(Login);
