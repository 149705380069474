import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import SupplierLayout from './layouts/SupplierLayout';
import NotImplemented from './views/pages/NotImplemented';
import PublicAuth from './components/PublicAuth';
import PublicAuthRoute from './components/PublicAuthRoute';

function Routes() {
  return (
    <PublicAuth>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Route
            path="/"
            render={(props: any) => (
              <SupplierLayout {...props}>
                <Switch>
                  <Route
                    path={['/forgotPassword']}
                    component={lazy(() => import('./publicViews/auth/ResetPasswordView'))}
                  />

                  <Redirect exact from="/" to="/procurement" />
                  <Route
                    path={['/procurement/:id/:view', '/procurement/:id']}
                    component={lazy(() => import('./publicViews/tender/TenderViewRedesign'))}
                  />
                  <Route path={['/procurement']} component={lazy(() => import('./publicViews/tender/TenderList'))} />
                  <Route path="/rules" component={lazy(() => import('./publicViews/help/Rules'))} />
                  <Route path="/directory" component={lazy(() => import('./publicViews/help/Directory'))} />
                  <Route path="/security" component={lazy(() => import('./publicViews/help/Security'))} />
                  <Route path="/faq" component={lazy(() => import('./publicViews/help/FAQ'))} />
                  <Route
                    path="/registration"
                    component={lazy(() => import('./publicViews/auth/RegisterViewRedesign'))}
                  />
                  <Redirect exact from="/" to="/procurement" />
                  <PublicAuthRoute
                    path="/"
                    render={(props: any) => (
                      <Switch>
                        <Route path="/dashboard" component={lazy(() => import('./publicViews/DashboardView'))} />
                        <Route
                          path={['/profile', '/staff']}
                          component={lazy(() => import('./publicViews/ProfileView'))}
                        />
                        <Route path="/newFolder" component={lazy(() => import('./publicViews/FoldersViewRegisign'))} />
                        <Route
                          path="/folder/:id"
                          component={lazy(() => import('./publicViews/tender/TenderListFoldersView'))}
                        />
                        <Route
                          path="/newSearchTemplate"
                          component={lazy(() => import('./publicViews/SearchTemplatesViewRegisign'))}
                        />
                        <Route path="/docs" component={lazy(() => import('./publicViews/DocsView'))} />
                        <Route
                          path={[
                            '/withNotes',
                            '/favorite',
                            '/myTenders',
                            '/winner',
                            '/searchTemplate/:id',
                            '/activeTenders',
                          ]}
                          component={lazy(() => import('./publicViews/tender/TenderList'))}
                        />
                        <Redirect exact path="*" to="/dashboard" />
                      </Switch>
                    )}
                  />

                  <Route component={NotImplemented} />
                </Switch>
              </SupplierLayout>
            )}
          />
        </Switch>
      </Suspense>
    </PublicAuth>
  );
}

export default Routes;
