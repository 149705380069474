import axios from '../utils/axios';
import { API } from '../constants/constants';
import Tender from '../types/Tender';
import { NewTenderFAQ } from '../types/TenderFAQ';
import { NewTenderQuestion } from '../types/public/Tender';
import { base64ToString } from '../utils/helper';

class TenderService {
  getList = (type = '', path = '') =>
    new Promise<Tender[]>((resolve, reject) => {
      let url = `${API}/Tender/List`;
      switch (type) {
        case '?type=m':
          url += '?type=MaterialsProcurement';
          break;
        case '?type=s':
          url += '?type=Service';
          break;
        case '?type=w':
          url += '?type=WorkPerforming';
          break;
        default:
      }
      switch (path) {
        case '/withNotes':
          url += '?withNotes=true';
          break;
        case '/favorite':
          url += '?favorite=true';
          break;
        case '/myTenders':
          url = `${API}/Tender/Active`;
          break;
        case '/activeTenders':
          url = `${API}/Tender/Active`;
          break;
        case '/winner':
          url = `${API}/Tender/Active`;
          break;
        default:
      }

      axios
        .get(url)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getListFromFolder = (folderId: number) =>
    new Promise<Tender[]>((resolve, reject) => {
      axios
        .get(`${API}/UserFolder/${folderId}/Tenders`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getTenderDetails = (id: number) =>
    new Promise<Tender>((resolve, reject) => {
      axios
        .get(`${API}/Tender/${id}`)
        .then(response => {
          if (response.data) {
            resolve({
              ...response.data,
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getTenderFaqs = (id: number) =>
    new Promise<NewTenderFAQ>((resolve, reject) => {
      axios
        .get(`${API}/TenderFaqs/${id}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getTenderFaqList = (ids: number[]) =>
    new Promise<NewTenderFAQ[]>((resolve, reject) => {
      axios
        .post(`${API}/TenderFaqs/List`, ids)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getTenderQuestionnaire = (id: number) =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/TenderQuestionnaireTemplate/${id}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  participantRequestDocuments = (tenderId: number | undefined, data: any) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${tenderId}/ParticipantRequestDocument`, data)
        .then(response => {
          if (response.data) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getTenderQuestions = (tenderId: number) =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/Tender/${tenderId}/Question`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  createTenderQuestion = (question: NewTenderQuestion, tenderId: number) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${tenderId}/Question`, question)
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  addRemoveTenderFav = (tenderId: number, isFavorite: boolean) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${tenderId}/Favorite`, {
          isFavorite,
        })
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  addNoteToTender = (tenderId: number, note: any) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${tenderId}/Notes`, { ...note })
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  applyTender = (tenderData: any) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Tender/Participate`, tenderData)
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getActiveTenders = () =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/Tender/Active`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  createCommentToQuestion = (questionId: number, tenderId: number, comment: string) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${tenderId}/Question/${questionId}/Comment`, { comment })
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  downloadFileById = (id: number, isRequested?: boolean) =>
    new Promise<any>((resolve, reject) => {
      let url = `${API}/Tender/File/${id}/Download${isRequested ? '?type=Requested' : ''}`;
      axios
        .get(url, {
          responseType: 'blob',
        })
        .then(response => {
          if (response.data) {
            resolve({
              fileData: response.data,
              type: response.headers['content-type'],
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getTenderType = (id: number) =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/TenderTypes/${id}`)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getContract = (id: number) =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/Tender/${id}/Contract`, {
          responseType: 'blob',
        })
        .then(response => {
          if (response.data) {
            resolve({
              fileData: response.data,
              type: response.headers['content-type'],
              name: base64ToString(response.headers['x-file-name']),
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  downloadTenderTypeFileById = (id: number) =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/TenderTypes/File/${id}/Download`, {
          responseType: 'blob',
        })
        .then(response => {
          if (response.data) {
            resolve({
              fileData: response.data,
              type: response.headers['content-type'],
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  sendReports = (id: number, data: any) =>
    new Promise<any>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${id}/FinishEmail`, data)
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  oapAdmit = (id: number, data: any) =>
    new Promise<any>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${id}/OAPAdmit`, data)
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  refuseParticipate = (id: number) =>
    new Promise<any>((resolve, reject) => {
      axios
        .post(`${API}/Tender/${id}/Participate/Refuse`)
        .then(response => {
          if (response.data) {
            resolve(true);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  downloadContract = (id: number) =>
    new Promise<any>((resolve, reject) => {
      axios
        .get(`${API}/Tender/${id}/GenerateContact`, {
          responseType: 'blob',
        })
        .then(response => {
          if (response.data) {
            resolve({
              fileData: response.data,
              type: response.headers['content-type'],
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  exportTenders = (tenders: number[]) => {};
}

const tenderService = new TenderService();

export default tenderService;
