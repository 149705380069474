import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import {
    Box,
    Button,
    CircularProgress,
    Drawer,
    IconButton,
    InputAdornment,
    Link,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
    Menu,
    MenuItem,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    Hidden,
    Toolbar,
    Collapse,
    List,
    ListItem, ListItemText, useMediaQuery, useTheme,
} from '@material-ui/core';
import { MenuItemChildren, MenuItemProps } from './index';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translate } from '../../../utils/i18nHelpers';
import { injectIntl, WrappedComponentProps } from 'react-intl';

const useStyles = makeStyles(() => ({
    menuBtn: {
        fontFamily: 'SegoeUI-Bold!important',
        borderRadius: 0,
        color: '#000078',
        letterSpacing: 'normal',
        fontSize: 16,
        marginRight: '3vw',
        '&:hover': {
            backgroundColor: 'transparent',
            '&:after': {
                width: '100%',
            }
        },
        '&:after': {
            content: '" "',
            position: 'absolute',
            bottom: 4,
            left: 0,
            right: 0,
            display: 'block',
            width: 0,
            margin: '0 auto',
            height: 1,
            background: '#000078',
            transition: '.3s',
        },
    },
    mobileButton: {
        maxHeight: 36,
        color: '#fff',
        '&:after': {
            background: 'transparent',
        },
    },
    container: {
        background: 'transparent',
        borderRadius: 15,
    },
    blur: {
        borderRadius: 15,
        background: 'hsl(0deg 0% 100% / 50%)',
        backdropFilter: 'blur(10px)'
    },
    menuItem: {
        fontFamily: 'SegoeUI-SemiBold',
        color: '#000078',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuItemText: {
        '&:hover': {
            backgroundColor: 'transparent',
            '&:after': {
                width: '100%',
            }
        },
        '&:after': {
            content: '" "',
            bottom: 4,
            left: 0,
            right: 0,
            display: 'block',
            width: 0,
            margin: '0 auto',
            height: 1,
            background: '#000078',
            transition: '.3s',
        },
    },
    redArrow: {
        fill: 'red'
    },
    link: {
        textDecoration: "none",
    }
}));

function MenuButton({ children, menu, onClickMobileMenu, ...rest }: { children: React.ReactNode, menu: MenuItemProps, onClickMobileMenu?: Function } & WrappedComponentProps) {
    const classes = useStyles();
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (menu.link && !menu.external) {
            history.push(menu.link);
            if (isMobileDevice && onClickMobileMenu) {
                onClickMobileMenu();
            }
            return;
        }
        if(menu.link && menu.external){
            window.open(menu.link, "_blank");
        }
        setAnchorEl(event.currentTarget);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        if (isMobileDevice && onClickMobileMenu) {
            onClickMobileMenu();
        }
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <>
            <Button ref={ anchorRef }
                    className={ clsx(classes.menuBtn, isMobileDevice ? classes.mobileButton : '') }
                    onClick={ handleClick }>
                { children } { menu.children && (
                <ExpandMoreIcon className={ classes.redArrow }/>) }
            </Button>
            { menu && menu.children && (
                <>
                    <Hidden mdDown>
                        <Popper
                            className={ classes.blur } open={ open } anchorEl={ anchorRef.current }
                            role={ undefined } transition
                            disablePortal>
                            { ({ TransitionProps, placement }) => (
                                <Grow
                                    { ...TransitionProps }
                                    style={ { transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' } }
                                >
                                    <Paper className={ classes.container }>
                                        <ClickAwayListener onClickAway={ handleClose }>
                                            <MenuList autoFocusItem={ open }>
                                                { menu.children && menu.children.map((item: MenuItemChildren, index: number) => {
                                                    if(!item.external){
                                                        return (<MenuItem
                                                            className={ classes.menuItem }
                                                            key={ index }
                                                            onClick={ handleClose }
                                                            component={ RouterLink }
                                                            to={ item.link }
                                                        >
                                                            <span className={classes.menuItemText}>
                                                                { translate(rest, `pub:TopBar;Menu;${ item.key }:item`, item.name) }
                                                            </span>
                                                        </MenuItem>);
                                                    }
                                                    return (<MenuItem
                                                        className={ classes.menuItem }
                                                        key={ index }
                                                        onClick={ handleClose }
                                                    >
                                                        <a className={classes.link} href={item.link} target={"_blank"}>{ translate(rest, `pub:TopBar;Menu;${ item.key }:item`, item.name) }</a>
                                                    </MenuItem>);
                                                }) }
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            ) }
                        </Popper>
                    </Hidden>
                    <Hidden lgUp>
                        <Collapse in={ open } timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                { menu.children && menu.children.map((item: MenuItemChildren, index: number) => {
                                    return (<ListItem
                                        button
                                        key={ index }
                                        onClick={ handleClose }
                                        component={ RouterLink }
                                        to={ item.link }
                                    >
                                        <ListItemText>
                                            { item.name }
                                        </ListItemText>
                                    </ListItem>);
                                }) }
                            </List>
                        </Collapse>
                    </Hidden>
                </>
            ) }
        </>
    );
}

export default injectIntl(MenuButton);
