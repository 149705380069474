import React, { useEffect, useState } from 'react';
import {
    Box,
    Button, Checkbox, FormControlLabel, FormHelperText, Link,
    makeStyles, TextField, Typography
} from '@material-ui/core';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { translate } from '../../../utils/i18nHelpers';
import { AttachedDocumentItem } from '../../../types/TenderDocuments';
import { base64ToFile, downloadSelfUploadedFile, toBase64 } from '../../../utils/helper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        display: 'none',
    },
    fileInput: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        padding: 15,
        marginTop: 15,
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        borderRadius: 4
    },
    fileData: {
        display: 'flex',
        marginBottom: 10
    },
    button: {
        height: 42
    },
    fileInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 15
    },
    link: {
        cursor: 'pointer',
        marginLeft: 10
    },
    icon: {},
    saveBtn: {
        marginTop: 10
    },
    title: {
        marginTop: 20,
        color: '#000078',
    },
    docItem: {
        display: 'flex',
        flexDirection: 'row',
        padding: 15,
        marginBottom: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    docItemName: {
        flexGrow: 1
    },
    docItemDate: {
        minWidth: 130,
        padding: '0 10px',
        boxSizing: 'content-box',
        display: 'flex',
        alignItems: 'center',
    },
    docItemActions: {
        boxSizing: 'content-box',
        minWidth: 215,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonNormalize: {
        height: 30,
        textTransform: 'none',
    },
    itemName: {
        fontSize: 17
    },
}));

interface DocumentItemProps {
    title: string;
    data: any;
    setFileToForm: Function;
    required?: boolean;
    showErrors?: boolean;
}

function NewFileItem({
                         title,
                         data,
                         setFileToForm,
                         required,
                         showErrors,
                         ...props
                     }: DocumentItemProps & WrappedComponentProps) {
    const classes = useStyles();
    const initialItem: AttachedDocumentItem = {
        _id: uuidv4(),
        id: 0,
        name: '',
        file: null,
    };
    const [fileData, setFileData] = useState<AttachedDocumentItem>(data || initialItem);

    const download = () => {
        if (fileData && fileData.file) {
            base64ToFile(fileData.file.content, fileData.file.name, fileData.file.type);
            return;
        }
    };

    const removeFile = () => {
        if (fileData) {
            setFileData(initialItem);
            return;
        }
    };

    const hasError = () => {
        const error = _.isEmpty(fileData.file);
        if (error && required && showErrors) {
            return translate(props, 'app:Global;validation:required', 'Required');
        }
        return null;
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files && files.length) {
            const formData = new FormData();
            formData.append('data', files[0]);
            const fileObj = getFileData(formData);
            const base64Data: string = await toBase64(files[0]);
            setFileData({
                ...fileData,
                file: {
                    content: base64Data,
                    type: fileObj.type,
                    name: fileObj.name,
                }
            });
            setFileToForm({
                ...fileData,
                file: {
                    content: base64Data,
                    type: fileObj.type,
                    name: fileObj.name,
                }
            });
        }
    };

    const getFileData = (data?: FormData) => {
        if (data) {
            const file = data.get('data');
            if (file && typeof file !== "string") {
                return {
                    name: file.name,
                    type: file.type
                };
            }
            return { name: 'file', type: '' };
        }
        return { name: '', type: '' };
    };

    return (<>
            <div className={ classes.docItem }>
                <div className={ classes.docItemName }>
                    { fileData.file &&
                    <Link variant="h5" className={ classes.link } onClick={ () => {
                        download();
                    } }>
                        { title }
                    </Link>
                    }
                    { !fileData.file &&
                    <Typography
                        gutterBottom
                        variant='h5'
                        color='textSecondary'
                        className={ classes.itemName }
                    >
                        { title }
                    </Typography>
                    }
                    { hasError() && (
                        <FormHelperText
                            error>
                            { hasError() }
                        </FormHelperText>
                    ) }
                </div>
                <div className={ classes.docItemDate }>
                    <Typography
                        variant='h5'
                    >
                    </Typography>
                </div>
                <div className={ classes.docItemActions }>
                    { fileData.file &&
                    <Button
                        className={ classes.buttonNormalize }
                        size="small"
                        startIcon={ <SaveIcon/> }
                        onClick={ () => {
                            download();
                        } }
                    >
                        { translate(props, 'pub:Documents;DownloadBtn:title') }
                    </Button>
                    }
                    { !fileData.file &&
                    <>
                        <input
                            className={ classes.input }
                            required={ Boolean(required) }
                            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                                handleFileChange(event);
                            } }
                            id={ `chooseFile-${ title }` }
                            type="file"
                        />
                        <label htmlFor={ `chooseFile-${ title }` }>
                            <Button
                                component="span"
                                className={ classes.buttonNormalize }
                                size="small"
                                startIcon={ <CloudUploadIcon/> }
                            >
                                { translate(props, 'pub:Documents;UploadBtn:title') }
                            </Button>

                        </label>
                    </>
                    }
                    <Button
                        className={ classes.buttonNormalize }
                        size="small"
                        component="span"
                        onClick={ () => {
                            removeFile();
                        } }
                        startIcon={ <DeleteIcon/> }
                    >
                        { translate(props, 'pub:Documents;RemoveBtn:title') }
                    </Button>
                </div>
            </div>
        </>
    );
}

export default injectIntl(NewFileItem);
