import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    TextField,
    makeStyles,
    FormControlLabel,
    FormHelperText,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import { translate } from '../../../utils/i18nHelpers';
import { PHONE_REGEX_KZ, PHONE_REGEX_UA } from '../../../constants/constants';
import _ from 'lodash';
import AccountObj from '../../../types/Account';
import authService from '../../../publicServices/authService';
import PublicAccount from '../../../types/public/PublicAccount';

const useStyles = makeStyles(() => ({
    root: {}
}));

export interface VerificationFormProps {
    className?: string;
    onSendSuccess: Function;
    props: any;
}

function VerificationForm({ className, onSendSuccess, props, ...rest }: VerificationFormProps & WrappedComponentProps) {
    const classes = useStyles();
    const { user } = useSelector((state: PublicAccount) => state.publicAccount);

    const initialValues = {
        email: user ? user.email : '',
        phoneNumber: user ? user.phoneNumber : '',
        verificationOption: 'Phone',
        errorList: '',
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ Yup.object().shape({
                email: Yup.string().email().max(255),
            }) }
            onSubmit={ async (values, {
                setStatus,
                setSubmitting,
                setErrors
            }) => {
                try {
                    await authService.sendVerification(user.id, values.email, values.phoneNumber, values.verificationOption);
                    onSendSuccess(values.verificationOption);
                } catch (error) {
                    const message = (error.response && error.response.data.message) || 'Something went wrong';
                    setStatus({ success: false });
                    setErrors({ errorList: translate(rest, `app:Verification;Error:${message}`) });
                    setSubmitting(false);
                }
            } }
        >
            { ({
                   errors,
                   handleBlur,
                   handleChange,
                   handleSubmit,
                   isSubmitting,
                   touched,
                   values
               }) => (
                <form
                    noValidate
                    className={ clsx(classes.root, className) }
                    onSubmit={ handleSubmit }
                    { ...rest }
                >
                    <FormControl fullWidth component="fieldset">
                        <FormLabel component="legend">
                            <FormattedMessage id="app:Verification;selectOption"
                                              defaultMessage="Choose a verification method:"/>
                        </FormLabel>
                        <RadioGroup name="verificationOption" value={ values.verificationOption }
                                    onChange={ handleChange }>
                            <FormControlLabel value="Phone" control={ <Radio/> }
                                              label={ translate(props, 'app:Verification;Option:phone', 'Phone') }/>
                            <TextField
                                error={ Boolean(touched.phoneNumber && errors.phoneNumber) }
                                fullWidth
                                autoFocus
                                helperText={ touched.phoneNumber && errors.phoneNumber }
                                label={
                                    <FormattedMessage id="app:Verification;Option;Phone:input"
                                                      defaultMessage="Phone"/>
                                }
                                margin="normal"
                                name="phoneNumber"
                                onBlur={ handleBlur }
                                onChange={ handleChange }
                                type="text"
                                value={ values.phoneNumber }
                                variant="outlined"
                            />
                            <FormControlLabel value="Email" control={ <Radio/> }
                                              label={ translate(props, 'app:Verification;Option:email', 'Email') }/>
                            <TextField
                                error={ Boolean(touched.email && errors.email) }
                                fullWidth
                                helperText={ touched.email && errors.email }
                                label={
                                    <FormattedMessage id="app:Verification;Option;Email:input"
                                                      defaultMessage="Email"/>
                                }
                                margin="normal"
                                name="email"
                                onBlur={ handleBlur }
                                onChange={ handleChange }
                                type="text"
                                value={ values.email }
                                variant="outlined"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Box mt={ 2 }>
                        <Button
                            color="secondary"
                            disabled={ isSubmitting }
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            <FormattedMessage id="app:Verification;SendCode:button"
                                              defaultMessage="Send code"/>
                        </Button>
                        { errors.errorList && (
                            <Box mt={ 3 }>
                                <FormHelperText error>
                                    { errors.errorList }
                                </FormHelperText>
                            </Box>
                        ) }
                    </Box>
                </form>
            ) }
        </Formik>
    );
}

export default injectIntl(VerificationForm);
